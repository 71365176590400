/*
    3. Slider CSS
*/

.slider__section--bg {
  background: url(../../img/slider/home1-slider-bg.webp);
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
}
.hero__slider--items {
  position: relative;
  padding: 5rem 0;
  @media only screen and (min-width: 992px) {
    padding: 7rem 0;
  }
  @media only screen and (min-width: 1200px) {
    padding: 7rem 0;
  }
  @media only screen and (min-width: 1600px) {
    padding: 10rem 0;
  }
}
.hero__slider--thumbnail{
  &__img{
    margin: 0 auto 2.5rem;
    transform: scale(1.3);
    @media only screen and (min-width: 768px) {
      margin: 0 auto 4rem;
    }
    @media only screen and (min-width: 1200px) and (max-width: 1599px){
      max-width: 730px;
    }
    @media only screen and (min-width: 992px) and (max-width: 1199px){
      max-width: 540px;
      margin: 0 auto 3rem;
    }
    @media only screen and (min-width: 768px) and (max-width: 991px){
      max-width: 480px;
      margin: 0 auto 2.5rem;
    }
    @media only screen and (max-width: 767px) {
      max-width: 360px;
    }
    @media only screen and (max-width: 479px) {
      max-width: 100%;
      padding: 0 20px;
    }
  }
}
.slider__content{
  &--maintitle{
    margin-bottom: 1.4rem;
    @media only screen and (min-width: 768px) {
      margin-bottom: 1.6rem;
    }
    @media only screen and (min-width: 992px) {
      margin-bottom: 2rem;
    }
  }
  &--desc{
    font-size: 1.5rem;
    line-height: 2.6rem;
    width: 88%;
    margin: 0 auto 1.5rem;
    @media only screen and (min-width: 576px) {
      font-size: 1.6rem;
      margin: 0 auto 1.8rem;
    }
    @media only screen and (min-width: 768px) {
      font-size: 1.6rem;
      line-height: 2.8rem;
      margin: 0 auto 2.2rem;
    }
    @media only screen and (min-width: 992px) {
      font-size: 1.7rem;
      line-height: 2.8rem;
      width: 70%;
      margin: 0 auto 3rem;
    }
    @media only screen and (min-width: 1200px) {
      font-size: 1.8rem;
    }
    @media only screen and (min-width: 1600px) {
      font-size: 2rem;
      line-height: 3rem;
    }
  }
}
.slider__play--bideo{
  margin-left: 2rem;
  @media only screen and (min-width: 768px) {
    margin-left: 3.5rem;
  }
}
.bideo__play {
  &--icon {
    width: 4.5rem;
    height: 4.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: var(--secondary-color);
    background: var(--white-color);
    border: 3px solid var(--secondary-color);
    position: relative;
    transition: 0.5s;
    animation: animate 2s linear infinite;
    transform: scale(1);
    &:hover {
      background: var(--secondary-color);
      color: var(--white-color);
      transform: scale(1.2);
    }
    @media only screen and (min-width: 768px) {
      width: 5rem;
      height: 5rem;
    }
    @media only screen and (min-width: 992px) {
      width: 5.5rem;
      height: 5.5rem;
    }
    & svg {
      @media only screen and (max-width: 767px) {
        width: 3.5rem;
      }
    }
  }
}

@keyframes animate {
  0% {
    box-shadow: 0 0 0 0 rgba(215, 35, 35, 0.7);
  }
  40% {
    box-shadow: 0 0 0 50px rgba(255, 193, 7, 0);
  }
  80% {
    box-shadow: 0 0 0 50px rgba(255, 193, 7, 0);
  }
  100% {
    box-shadow: 0 0 0 rgba(255, 193, 7, 0);
  }
}

.hero__slider--activation {
  & .swiper-button-prev {
    left: 1rem;
    @media only screen and (min-width: 768px) {
      left: 1rem;
    }
    @media only screen and (min-width: 992px) {
      left: 2rem;
    }
  }
  & .swiper-button-next {
    right: 1rem;
    @media only screen and (min-width: 768px) {
      right: 1rem;
    }
    @media only screen and (min-width: 992px) {
      right: 2rem;
    }
  }
}

.slider__pagination.swiper-pagination{
  position: absolute;
  top: 50%;
  bottom: auto !important;
  width: auto;
  flex-direction: column;
  display: flex;
  align-items: center;
  transform: translatey(-50%);
  left: 20px;
  @media only screen and (min-width: 1200px) {
    left: 30px;
  }
  @media only screen and (min-width: 1366px) {
    left: 80px;
  }
  @media only screen and (min-width: 1600px) {
    left: 120px;
  }
  @media only screen and (max-width: 767px) {
    left: inherit;
    position: inherit;
    flex-direction: row-reverse;
    justify-content: center;
    bottom: 23px !important;
    transform: inherit;
  }
  & .swiper-pagination-bullet{
    @media only screen and (max-width: 767px) {
      width: 1.8rem;
      height: 1.8rem;
      margin: 0 0.3rem !important;
    }
  }
}

.swiper-slide-active .slider__content > *{
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.swiper-slide-active {
  & .hero__slider--thumbnail__img{
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transition: 1.4s;
  }
  & .slider__content--maintitle {
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
  }
  & .slider__content--desc {
    -webkit-animation-delay: 0.9s;
    animation-delay: 0.9s;
  }
  & .slider__content--btn {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
  }
  & .slider__content--footer {
    -webkit-animation-delay: 1.5s;
    animation-delay: 1.5s;
  }
}


// home two css here
.home2__slider--items__bg{
  height: 300px;
  padding: 30px 0;
  display: flex;
  align-items: center;
  &.one{
    background: url(../../img/slider/home2-slider-bg.webp);
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: cover;
    @media only screen and (max-width: 575px) {
      background-position: 20%;
    }
    @media only screen and (max-width: 450px) {
      background-position: 33%;
    }
  }
  &.two{
    background: url(../../img/slider/home2-slider-bg2.webp);
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: cover;
    @media only screen and (max-width: 575px) {
      background-position: 20%;
    }
    @media only screen and (max-width: 450px) {
      background-position: 33%;
    }
  }
  &.three{
    background: url(../../img/slider/home2-slider-bg3.webp);
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: cover;
    @media only screen and (max-width: 575px) {
      background-position: 20%;
    }
    @media only screen and (max-width: 450px) {
      background-position: 33%;
    }
  }
  &.four{
    background: url(../../img/slider/home2-slider-bg4.webp);
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: cover;
    @media only screen and (max-width: 575px) {
      background-position: 20%;
    }
    @media only screen and (max-width: 450px) {
      background-position: 33%;
    }
  }
  @media only screen and (min-width: 450px) {
    height: 320px;
  }
  @media only screen and (min-width: 576px) {
    height: 400px;
  }
  @media only screen and (min-width: 768px) {
    height: 430px;
  }
  @media only screen and (min-width: 992px) {
    height: 530px;
  }
  @media only screen and (min-width: 1200px) {
    height: 550px;
  }
  @media only screen and (min-width: 1366px) {
    height: 570px;
  }
  @media only screen and (min-width: 1600px) {
    height: 800px;
  }
  
}


.slider__content{
  &.style2{
    @media only screen and (min-width: 768px) {
      margin-left: 4.5rem;
    }
    @media only screen and (min-width: 992px) {
      margin-left: 5rem;
    }
    @media only screen and (min-width: 1200px) {
      margin-left: 7rem;
    }
    @media only screen and (min-width: 1366px) {
      margin-left: 0;
    }
    @media only screen and (max-width: 575px) {
      text-align: center;
    }
  }
  &--desc{
    &.style2{
      margin: 0 0 2rem;
      width: 66%;
      @media only screen and (min-width: 768px) {
        width: 58%;
      }
      @media only screen and (min-width: 992px) {
        margin: 0 0 3rem;
        width: 55%;
      }
    }
  }
  &--footer.style2{
    @media only screen and (max-width: 575px) {
      justify-content: center;
    }
  }
}


.slider__pagination.style2.swiper-pagination{
  @media only screen and (min-width: 1366px) {
    left: 20px;
  }
  @media only screen and (min-width: 1600px) {
    left: 100px;
  }
  @media only screen and (max-width: 767px) {
    bottom: 35px !important;
  }
}


// home three css here
.slider__section--bg3{
  background: url(../../img/slider/home3-slider-bg.webp);
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
  height: 320px;
  display: flex;
  align-items: center;
  @media only screen and (min-width: 768px) {
    height: 400px;
  }
  @media only screen and (min-width: 992px) {
    height: 470px;
  }
  @media only screen and (min-width: 1200px) {
    height: 500px;
  }
  @media only screen and (min-width: 1366px) {
    height: 530px;
  }
  @media only screen and (min-width: 1600px) {
    height: 710px;
  }
  @media only screen and (max-width: 991px) {
    background-position: 15%;
  }
  @media only screen and (max-width: 575px) {
    background-position: 5%;
  }
}

.slider__content{
  &.style3{
    @media only screen and (max-width: 767px) {
      text-align: center;
    }
  }
  &--desc{
    &.style3{
      margin: 0 auto 1.5rem;
      width: 100%;
      @media only screen and (min-width: 576px) {
        margin: 0 auto 2rem;
        width: 90%;
      }
      @media only screen and (min-width: 768px) {
        margin: 0 0 2rem;
        width: 84%;
      }
      @media only screen and (min-width: 992px) {
        margin: 0 0 3rem;
      }
    }
  }
  &--footer.style3{
    @media only screen and (max-width: 767px) {
      justify-content: center;
    }
  }
}

// home four css here
.slider__section--bg4{
  background: url(../../img/slider/home4-slider-bg.webp);
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
}
.hero__slider--items.style4{
  height: auto;
  display: flex;
  align-items: center;
  padding: 0;
  @media only screen and (min-width: 768px) {
    height: 455px;
  }
  @media only screen and (min-width: 992px) {
    height: 530px;
  }
  @media only screen and (min-width: 1200px) {
    height: 600px;
  }
  @media only screen and (min-width: 1366px) {
    height: 650px;
  }
  @media only screen and (min-width: 1600px) {
    height: 900px;
  }
  @media only screen and (max-width: 767px) {
    padding: 5rem 0 5.5rem;
  }
}
.hero__slider--thumbnail{
  &__img{
    &.style4{
      max-width: 200px;
      @media only screen and (min-width: 768px) {
        margin: inherit;
        max-width: 275px;
      }
      @media only screen and (min-width: 992px) {
        max-width: 320px;
        margin: 0 auto;
      }
      @media only screen and (min-width: 1200px) {
        max-width: 350px;
      }
      @media only screen and (min-width: 1366px) {
        max-width: 380px;
      }
      @media only screen and (min-width: 1600px) {
        max-width: 480px;
      }
    }
  }
}

.slider__content{
  &.style4{
    @media only screen and (max-width: 767px) {
      text-align: center;
    }
  }
  &--desc{
    &.style4{
      width: 100%;
      margin: 0 0 2rem;
      @media only screen and (min-width: 992px) {
        width: 90%;
        margin: 0 0 3rem;
      }
    }
  }
  &--footer{
    &.style4{
      @media only screen and (max-width: 767px) {
       justify-content: center;
      }
    }
  }
}



.slider__pagination.style4.swiper-pagination{
  left: auto;
  right: 20px;
  @media only screen and (min-width: 1200px) {
    right: 30px;
  }
  @media only screen and (min-width: 1366px) {
    right: 60px;
  }
  @media only screen and (min-width: 1600px) {
    right: 100px;
  }
  @media only screen and (max-width: 767px) {
    left: inherit;
    right: inherit;
    bottom: 23px !important;
  }
}