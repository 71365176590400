/*
    8. Services CSS
*/
.services__section--bg {
    background: url(../../img/banner/bg-services.jpg)
}

.services__card {
    box-shadow: 0 0 38px rgba(0, 0, 0, 0.05);
    position: relative;
    background: var(--white-color);
    transition: var(--transition);
    display: block;

    // &::after{
    //     position: absolute;
    //     content: "";
    //     background: url(../../img/icon/services-card-shape.webp);
    //     width: 46px;
    //     height: 70px;
    //     right: 0;
    //     top: 0;
    //     transition: var(--transition);
    //     @media only screen and (min-width: 768px) {
    //         width: 82px;
    //         height: 81px;
    //     }
    // }
    &::before {
        position: absolute;
        content: "";
        background: var(--secondary-color);
        top: 1rem;
        bottom: 1rem;
        left: 1rem;
        right: 1rem;
        opacity: 0;
        transition: var(--transition);
    }

    &:hover {
        transform: translateY(-15px);
        box-shadow: 0 5px 20px rgba(0, 0, 0, 0.16);

        &::before {
            opacity: 0.8;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
        }

        & .services__card--primary__icon {
            opacity: 0;
            visibility: hidden;
        }

        & .services__card--secondary__icon {
            opacity: 1;
            visibility: visible;
        }

        & .services__card--maintitle {
            color: var(--white-color);
        }

        & .services__card--category {
            color: var(--white-color);
        }

        & .services__card--desc {
            color: var(--white-color);
        }

        & .services__card--readmore {
            color: var(--white-color);
        }

        & .services__card--number {
            opacity: 1;
            visibility: visible;
        }
    }

    &--link {
        padding: 1rem;
        position: relative;
        z-index: 9;
        // @media only screen and (min-width: 576px) {
        //     padding: 2.5rem 2rem;
        // }
        // @media only screen and (min-width: 768px) {
        //     padding: 3rem 2.5rem;
        // }
        // @media only screen and (min-width: 1200px) {
        //     padding: 4rem;
        // }
    }

    &--icon {
        position: relative;
        display: inline-block;

        & img {
            @media only screen and (max-width: 575px) {
                max-width: 70px;
            }

            @media only screen and (max-width: 479px) {
                max-width: 56px;
            }
        }
    }

    &--secondary__icon {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        visibility: hidden;
    }

    &--number {
        width: 5.3rem;
        height: 5.3rem;
        border: 1px solid var(--white-color);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        opacity: 0;
        visibility: hidden;
        transition: var(--transition);

        @media only screen and (max-width: 575px) {
            width: 4.6rem;
            height: 4.6rem;
        }

        &__text {
            width: 4.5rem;
            height: 4.5rem;
            background-color: var(--white-color);
            color: var(--secondary-color);
            font-size: 2.6rem;
            font-weight: 500;
            border-radius: 50%;
            line-height: 4.5rem;
            text-align: center;

            @media only screen and (max-width: 575px) {
                width: 3.6rem;
                height: 3.6rem;
                line-height: 3.6rem;
                font-size: 2rem;
            }
        }
    }

    &--maintitle {
        transition: var(--transition);
    }

    &--category {
        transition: var(--transition);
        font-size: 13px;
        font-weight: 600;
    }

    &--desc {
        transition: var(--transition);
    }

    &--readmore {
        font-size: 1.6rem;
        font-weight: 500;

        &__svg {
            vertical-align: middle;
            margin-left: 0.3rem;
        }

        @media only screen and (min-width: 768px) {
            font-size: 1.7rem;
        }
    }

}