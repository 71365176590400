/* 
    27. Portfolio css 
*/
.portfolio__items{
    &--thumbnail{
        &::before{
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            background: var(--secondary-color);
            top: 0;
            left: 0;
            opacity: 0;
            transition: var(--transition);
            z-index: 9;
            pointer-events: none;
        }
        &:hover{
            &::before{
                opacity: 0.6;
            }
        }
        &__link{
            overflow: hidden;
            display: block;
        }
        &:hover{
            & .portfolio__items--thumbnail__img{
                transform: scale(1.1);
            }
            & .portfolio__view--icon{
                transform: translatey(-50%) translatex(-50%) scale(1);
            }
        }
        &__img{
            transition: .3s;
            display: block;
        } 
    }
}

.portfolio__view--icon{
    width: 4rem;
    height: 4rem;
    background: var(--white-color);
    color: var(--secondary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    z-index: 9;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translatey(-50%) translatex(-50%) scale(0);
    & svg{
        width: 2.7rem;
    }
    &:hover{
        background: var(--primary-color);
        color: var(--white-color);
    }
}