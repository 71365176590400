.assets__value {
    color: #D72323;
    float: right;
    font-weight: 700;
}

.wallet__balance {
    color: #D72323;
    font-weight: 700;
}

.pull-right {
    float: right;
}

.recharge__btn {
    height: 3.8rem;
    line-height: 3.8rem;
    padding: 0 17px;
    margin-left: 12px;
    font-size: 1.4rem;
}

.justify-content-end {
    justify-content: flex-end;
}

.justify-content-center {
    justify-content: center;
}

.account__content--title.active {
    color: var(--secondary-color);
}

.user__detials {
    padding: 5px 10px;
    line-height: 1.5;
}

.align-items-end {
    align-items: flex-end;
}

.align-items-center {
    align-items: center;
}

.p-5 {
    padding: 5px;
}

.p-15 {
    padding: 15px;
}

.pt-5 {
    padding-top: 5px !important;
}

.lh-1 {
    line-height: 1;
}

.text-align-start {
    text-align: start;
}

.invite__code {
    flex-direction: column;
    align-items: center;
}

.fee__text {
    padding-left: 10px;
    font-size: 12px;

}

.category__live,
.category__Time {
    background-color: var(--secondary-color);
    padding: 2px;
    border-radius: 30px;
    color: white;
    display: flex;
    align-items: center;
    margin-top: 2px;
    justify-content: center;
    width: 55px;
    margin: auto;
}

.category__circle {
    width: 10px;
    height: 10px;
    background: #0d9101;
    border-radius: 50%;
    margin-right: 5px;
}


.category__live::after {
    content: "";
    position: absolute;
    top: 30%;
    // left: 50%;
    margin-left: 0px;
    border-width: 5px;
    border-style: solid;
    border-color: var(--secondary-color) transparent transparent transparent;
}

.blink_me {
    animation: blinker 2s linear infinite;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

.was-validated :invalid~.invalid-feedback,
.was-validated :invalid~.invalid-tooltip,
.is-invalid~.invalid-feedback,
.is-invalid~.invalid-tooltip {
    display: block;
}

.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    margin-bottom: 1.5rem;
    font-size: 0.875em;
    color: #dc3545;
}

.buy__now-btn {
    position: absolute;
    bottom: 0px;
    right: 5%;
}

.category__live--page {
    background-color: var(--secondary-color);
    padding: 2px;
    border-radius: 30px;
    color: white;
    display: flex;
    align-items: center;
    margin-top: 2px;
    justify-content: center;
    width: 55px;
    margin: auto;
    position: absolute;
    right: 20px;
    top: 10px;
}

.sold {
    background-color: #096612;
}

.text-justify {
    text-align: justify;
}

.qr_img {
    width: 75%;
    margin: auto;
}

.dashboard__block {
    border: 1px solid;
    border-radius: 81px;
    display: flex;
    justify-content: space-around;
    min-height: 75px;
    align-items: center;
    background: #bf0d3e;
    color: white;
    width: 100%;
    margin: 1.5rem;
}

.dashboard__block:hover {
    cursor: pointer;
}

.curson-pointer {
    cursor: pointer;
}

.w-100 {
    width: 100% !important;
}

@media only screen and (max-width: 479px) {
    .dashboard__block {
        width: -webkit-fill-available;
    }
}

.alphabetic-list li {
    display: inline-block;
    padding: 0px 8px;
    border-right: 1px solid black;
}

.alphabetic-list--catg li {
    display: inline-block;
    padding: 0px 15px;
    font-size: 18px;
    font-weight: 600;
}

.alphabetic-list li:last-child {
    border: none;
}

.alphabetic-list {
    margin-bottom: 10px;
    border: 1px solid #fc5b62;
    border-radius: 0px 10px 10px 0px;
    padding: 2px;
    width: fit-content;
    /* animation: alphabetic-list-open 1s; */
    /* animation-direction: reverse; */
    visibility: hidden;

    &--catg {
        visibility: visible !important;
        margin-bottom: 10px;
        border-radius: 0px 10px 10px 0px;
        padding: 2px;
        width: fit-content;
    }
}

.alphabetic-list a.active {
    border-radius: 37;
    color: white;
    /* border: 1px solid; */
    border-radius: 63px;
    padding: 0px 4px;
    background: #fc5b62;
}

.alphabetic-list--catg a.active {
    color: #fc5b62;
}

.btn-alpha {
    width: 100%;
    color: white;
    background-color: #fc5b62;
    border: 1px solid #fc5b62;
    border-radius: 10px;
    padding-bottom: 3px;
}

.btn-alpha.active {
    border-radius: 10px 0px 0px 10px;
}

.open-list {
    animation: alphabetic-list-open 700ms linear;
    display: block;
    margin-bottom: 10px;
    border: 1px solid #fc5b62;
    border-radius: 10px;
    padding: 2px;
    width: fit-content;
    visibility: visible;
}


.close-list {
    display: none;
    animation: alphabetic-list-open 1s;
    animation-direction: reverse;
}

@keyframes alphabetic-list-open {
    0% {
        width: 0%;
        height: 28px;
        overflow: hidden;
    }

    25% {
        width: 25%;
        height: 28px;
        overflow: hidden;
    }

    50% {
        width: 50%;
        height: 28px;
        overflow: hidden;
    }

    75% {
        width: 75%;
        height: 28px;
        overflow: hidden;
    }

    100% {
        width: fit-content;
    }
}

.clear {
    position: absolute;
    top: 0;
    right: 45px;
    background: transparent;
    border: none;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-button-next,
.swiper-button-prev {
    color: gray !important;
    opacity: 0.3;
}

.swiper-button-prev:hover {
    opacity: 1;
}

.swiper-button-next:hover {
    opacity: 1;
}

.f-20 {
    font-size: 20px;
}

.box_grid {
    background-color: #fff;
    display: block;
    position: relative;
    margin-bottom: 30px;
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
}

.box_grid .wrapper {
    padding: 25px;
}

.product-img {
    width: 100%;
    object-fit: scale-down;
}

.box_grid figure {
    margin-bottom: 0;
    overflow: hidden;
    position: relative;
    height: 210px;
}

.card--link {
    width: 100%;
}