/* 
    30. Shop page css 
*/
.shop__header {
    padding: 1.5rem 2rem;
    border-radius: 0.5rem;
    @media only screen and (min-width: 992px) and (max-width: 1199px){
        flex-direction: column;
    }
    @media only screen and (max-width: 479px) {
        flex-wrap: wrap;
        justify-content: center;
    }
}
.product__view{
    &--mode{
        &__list{
            margin-right: 2rem;
            &:last-child{
                margin-right: 0;
            }
            @media only screen and (min-width: 992px) {
                margin-right: 2rem;
            }
            @media only screen and (min-width: 1200px) {
                margin-right: 2.5rem;
            }
            @media only screen and (min-width: 1366px) {
                margin-right: 3.5rem;
            }
            @media only screen and (max-width: 991px) {
                margin-right: 0;
            }
        }
    }
    &--label{
        font-weight: 600;
        margin-right: 1.2rem;
        font-size: 1.4rem;
        @media only screen and (min-width: 992px) {
            margin-right: 1rem;
            font-size: 1.5rem;
        }
        @media only screen and (min-width: 1200px) {
            margin-right: 2.5rem;
        }
        @media only screen and (min-width: 1366px) {
            margin-right: 3.5rem;
        }
    }
    &--select{
        border: 1px solid var(--border-color);
        padding: 0.6rem 3rem 0.6rem 1.2rem;
        -webkit-appearance: none;
        cursor: pointer;
        border-radius: 0.5rem;
        background: var(--white-color);
        font-size: 1.4rem;
        @media only screen and (min-width: 992px) {
            font-size: 1.5rem;
        }
    }
}
.select.shop__header--select{
    &::before{
        right: 14px;
    }
}
.product__grid--column__buttons {
    &--icons{
        border: 1px solid var(--border-color);
        background: var(--white-color);
        border-radius: 0.3rem;
        width: 3.7rem;
        height: 3.7rem;
        line-height: 1.1rem;
        margin-right: 10px;
        text-align: center;
        padding: 0;
        &:last-child{
            margin-right: 0;
        }
        &:hover{
            color: var(--theme-color);
            border-color: var(--theme-color);
        }
        &.active{
            color: var(--theme-color);
            border-color: var(--theme-color);
        }
        & > *{
            pointer-events: none;
        }
    }
}

.product__showing--count{
    font-weight: 500;
    letter-spacing: 0.5px;
    font-size: 1.4rem;
    @media only screen and (min-width: 992px) {
        font-size: 1.5rem;
        line-height: 2.6rem;
        margin-top: 1.5rem;
    }
    @media only screen and (min-width: 1200px) {
        line-height: 2.8rem;
        margin-left: 20px;
        margin-top: 0;
    }
    @media only screen and (max-width: 479px) {
        text-align: center;
        width: 100%;
        margin-top: 1.2rem;
    }
}
.select {
    position: relative;
    &::before{
        border-bottom: 2px solid var(--black-color);
        border-right: 2px solid var(--black-color);
        content: '';
        display: block;
        height: 7px;
        margin-top: -4px;
        pointer-events: none;
        position: absolute;
        right: 18px;
        top: 50%;
        width: 7px;
        -webkit-transform-origin: 66% 66%;
        transform-origin: 66% 66%;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        opacity: 0.7;
    }
}

// sidebar widget css here
.offcanvas__filter--sidebar{
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 300px;
    height: 100vh;
    transition: var(--transition);
    transform: translateX(-100%);
    background-color: var(--white-color);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    overflow-y: auto;
    @media only screen and (min-width: 480px) {
        max-width: 320px;
    }
    &.active{
        opacity: 1;
        visibility: visible;
        transform: translateX(0);
    }
    @media only screen and (min-width: 1366px){
        .small__product--title {
            font-size: 1.5rem;
        }
    }

}
.widget__filter--btn{
   border: 1px solid var(--border-color);
   background: var(--white-color);
   border-radius: 0.3rem;
   padding: 0.5rem 1.2rem;
    @media only screen and (max-width: 479px) {
        margin-right: 2.5rem;
    }
   &:hover{
       border-color: var(--secondary-color);
       color: var(--secondary-color);
       & span{
         color: var(--secondary-color);
       }
   }
   &__icon{
       width: 20px;
   }
   &__text{
       font-weight: 600;
       font-size: 1.5rem;
       margin-left: 0.7rem;
   }
    & > *{
        pointer-events: none;
    }
   
}
.offcanvas__filter--close{
    display: flex;
    align-items: center;
    border: 1px solid var(--border-color);
    padding: 0.5rem 1rem;
    border-radius: 0.3rem;
    margin: 25px;
    background: var(--white-color);
    &:hover{
        border-color: var(--secondary-color);
        color: var(--secondary-color);
    }
    &__text{
        font-size: 1.5rem;
        font-weight: 600;
        margin-left: 8px;
    }
    & > *{
        pointer-events: none;
    }
}
.shop__sidebar--widget{
    position: sticky;
    top: 100px;
}
.single__widget{
    margin-bottom: 2.5rem;
    @media only screen and (min-width: 768px) {
        margin-bottom: 3rem;
    }
    &:last-child{
        margin-bottom: 0;
    }
    &.widget__bg{
        padding: 2rem;
        border-radius: 0.5rem;
        box-shadow: 0 2px 22px rgba(0, 0, 0, 0.10);
    }
}

.widget__title{
    font-weight: 600;
    margin-bottom: 1.5rem;
    border-bottom: 1px solid var(--border-color2);
    padding-bottom: 1rem;
    @media only screen and (min-width: 992px) {
        padding-bottom: 1.2rem;
        margin-bottom: 2.5rem;
    }
}
.widget__search{
    &--form{
        position: relative;
        &__input{
            width: 100%;
            height: 4.5rem;
            padding: 0 6rem 0 1.2rem;
            border-radius: 0.5rem;
            border: 1px solid var(--border-color2);
            @media only screen and (min-width: 992px) {
                font-size: 1.5rem;
            } 
        }
        &__btn{
            position: absolute;
            top: 0;
            right: 0;
            border: 0;
            background: var(--secondary-color);
            color: var(--white-color);
            padding: 0;
            width: 4.5rem;
            height: 100%;
            border-radius: 0 0.5rem 0.5rem 0;
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover{
                background: var(--primary-color);
            }
        }
    }
}

.widget__categories{
    &--menu{
        &__list{
            border: 1px solid var(--border-color);
            margin-bottom: 1.5rem;
            border-radius: 0.5rem;
            &.active{
                & .widget__categories--menu__label{
                    margin-bottom: 0;
                }
                & .widget__categories--menu__arrowdown--icon{
                    transform: rotate(180deg);
                    top: 38%;
                }
            }
        }
        &__label{
            cursor: pointer;
            padding: 0.5rem 1rem;
            position: relative;
            transition: var(--transition);
            &:hover{
                color: var(--secondary-color);
            }
            & > *{
                pointer-events: none;
            }
        }
        &__text{
            padding-left: 1rem;
            font-size: 1.4rem;
            @media only screen and (min-width: 992px) {
                font-size: 1.5rem;
            }
        }
        &__img{
            width: 3rem;
        }
        &__arrowdown--icon{
            position: absolute;
            right: 1.5rem;
            top: 43%;
            transition: var(--transition);
        }
    }
    &--sub__menu{
        display: none;
        padding: 1rem 0;
        border-top: 1px solid var(--border-color);
        &--list{
            margin-bottom: 0.8rem;
            padding: 0 1rem 0.8rem;
            border-bottom: 1px solid var(--border-color);
            &:last-child{
                margin-bottom: 0;
                padding-bottom: 0;
                border-bottom: 0;
            }
        }
        &--img{
            width: 2.8rem;
        }
        &--text{
            padding-left: 1rem;
            line-height: 2.8rem;
            font-size: 1.4rem;
            @media only screen and (min-width: 992px) {
                font-size: 1.5rem;
            }
        }
    }
}

.widget__form--check{
    &__list{
        margin-bottom: 1rem;
        position: relative;
        &:last-child{
            margin-bottom: 0;
        }
    }
    &__label{
        cursor: pointer;
        line-height: 2.8rem;
        transition: var(--transition);
        border: 1px solid var(--border-color);
        background: var(--white-color);
        padding: 0.5rem 3rem 0.5rem 1.5rem;
        border-radius: 0.5rem;
        display: block;
        font-size: 1.4rem;
        &:hover{
            color: var(--secondary-color);
        }
        @media only screen and (min-width: 992px) {
            font-size: 1.5rem;
        }
    }
    &__input{
        position: absolute;
        right: 1rem;
        top: 50%;
        transform: translatey(-50%);
        opacity: 0;
        cursor: pointer;
        z-index: 999;
        &:checked ~ .widget__form--checkmark{
            background-color: var(--secondary-color);
            border: 1px solid var(--secondary-color);
            &::before{
                display: block;
            }
        }
    }
}
.widget__form--checkmark{
    height: 1.8rem;
    width: 1.8rem;
    border: 1px solid var(--secondary-color);
    border-radius: 50%;
    display: block;
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translatey(-50%);
    background: var(--white-color);
    transition: var(--transition);
    &::before{
        right: 0.5rem;
        top: 0.3rem;
        width: 0.5rem;
        height: 0.8rem;
        border: solid white;
        border-top-width: medium;
        border-right-width: medium;
        border-bottom-width: medium;
        border-left-width: medium;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        content: "";
        position: absolute;
        display: none;
       
    }
}

.price__filter{
    &--group{
        width: 46%;
    }
    &--label{
        font-weight: 600;
        margin-bottom: 0.6rem;
        display: inline-block;
        @media only screen and (min-width: 992px) {
            font-size: 1.5rem;
        }
    }
    &--input{
        border: 1px solid var(--border-color2);
        padding: 0.7rem 1rem;
    }
    &--currency{
        font-weight: 700;
        margin-right: 0.6rem;
    }
    &--btn{
        height: 4rem;
        line-height: 4rem;
    }
}
.price__divider {
    font-size: 2rem;
    padding: 2.5rem 1rem 0

}
.widget__tagcloud{
    &--list{
        display: inline-block;
        margin: 0 7px 7px 0;
    }
    &--link{
        border: 1px solid var(--border-color2);
        background: var(--white-color);
        padding: 0.5rem 1.2rem;
        line-height: 2.4rem;
        border-radius: 0.3rem;
        font-size: 1.4rem;
        @media only screen and (min-width: 992px) {
            font-size: 1.5rem;
        }
        &:hover{
            background: var(--secondary-color);
            border-color: var(--secondary-color);
            color: var(--white-color);
        }
    }
}

.small__product{
    &:hover{
        & .small__product--img{
            transform: scale(1.04);
        }
    }
    &--thumbnail{
        width: 7.5rem;
        overflow: hidden;
        @media only screen and (min-width: 1366px) {
            width: 9rem;
        }
        @media only screen and (min-width: 1600px) {
            width: 10rem;
        }
    }
    &--content{
        width: calc(100% - 7.5rem);
        padding-left: 1.2rem;
        @media only screen and (min-width: 1366px) {
            width: calc(100% - 9rem);
            padding-left: 1.5rem;
        }
        @media only screen and (min-width: 1600px) {
            width: calc(100% - 10rem);
            padding-left: 1.8rem;
        }
    }
    &--title{
        font-size: 1.4rem;
        line-height: 2.2rem;
        margin-bottom: 0.6rem;
        @media only screen and (min-width: 1366px) {
            font-size: 1.6rem;
            line-height: 2.4rem;
        }
        @media only screen and (min-width: 1600px) {
            font-size: 1.7rem;
        }
    }
    &--price{
        margin-bottom: 0.4rem;
        @media only screen and (min-width: 1366px) {
            margin-bottom: 0.5rem;
        }
        & .current__price{
            font-size: 1.5rem;
            line-height: 2rem;
            @media only screen and (min-width: 1366px) {
                font-size: 1.6rem;
            }
        }
        & .old__price{
            font-size: 1.4rem;
            line-height: 2rem;
            @media only screen and (min-width: 1366px) {
                font-size: 1.5rem;
            }
        }
    }
}

// shop product wrapper css here
.shop__product--wrapper{
    & .product__items{
        &--link{
            @media only screen and (max-width: 991px) {
                width: 100%;
            }
        }
        &--img{
            @media only screen and (max-width: 991px) {
                width: 100%;
            }
        }
    }
}
.product__card--list{
    @media only screen and (max-width: 767px) {
        align-items: center;
    }
    @media only screen and (max-width: 399px) {
        flex-direction: column;
    }
    &__thumbnail{
        width: 100%;
        @media only screen and (min-width: 400px) {
            width: 170px;
        }
        @media only screen and (min-width: 480px) {
            width: 200px;
        }
        @media only screen and (min-width: 768px) {
            width: 270px;
        }
        @media only screen and (min-width: 992px) {
            width: 300px;
        }
        @media only screen and (min-width: 1366px) {
            width: 370px;
        }
    }
    &__content{
        width: 100%;
        padding: 1.6rem 0 0 0;
        box-shadow: inherit;
        @media only screen and (min-width: 400px) {
            width: calc(100% - 170px);
            padding: 0 0 0 1.5rem;
        }
        @media only screen and (min-width: 480px) {
            width: calc(100% - 200px);
            padding: 0 0 0 2rem;
        }
        @media only screen and (min-width: 768px) {
            width: calc(100% - 270px);
        }
        @media only screen and (min-width: 992px) {
            width: calc(100% - 300px);
            padding: 0 0 0 3rem;
        }
        @media only screen and (min-width: 1366px) {
            width: calc(100% - 370px);
        }
        @media only screen and (max-width: 399px) {
            text-align: center;
        }
        & .product__card--price{
            margin-bottom: 1rem;
            @media only screen and (min-width: 1200px) {
                margin-bottom: 1.5rem;
            }
        }
    }
}
.product__card--list__rating{
    margin-bottom: 1.5rem;
    @media only screen and (min-width: 768px) {
        margin-bottom: 2rem;
    }
    @media only screen and (min-width: 1200px) {
        margin-bottom: 1.2rem;
    }
    @media only screen and (max-width: 399px) {
        justify-content: center;
    }
}
.rating__list--text{
    color: var(--text-gray-color);
    font-size: 1.4rem;
}

// product pagination css here
.pagination__area{
    padding: 1.5rem 2rem;
    margin-top: 3rem;
    @media only screen and (min-width: 768px) {
        margin-top: 5rem;
    }
}
.pagination__list{
    margin-right: 1.2rem;
    &:last-child{
        margin-right: 0;
    }
}
.pagination__item{
    width: 3.5rem;
    height: 3.5rem;
    line-height: 3.3rem;
    font-size: 1.6rem;
    font-weight: 600;
    text-align: center;
    border-radius: 50%;
    border: 1px solid var(--border-color2);
    background: var(--white-color);
    @media only screen and (min-width: 768px) {
        width: 4rem;
        height: 4rem;
        line-height: 3.8rem;
    }
    &--current{
        background: var(--secondary-color);
        border-color: var(--secondary-color);
        color: var(--white-color);
    }
    &:hover{
        background: var(--secondary-color);
        border-color: var(--secondary-color);
        color: var(--white-color);
    }
}


.shop__header--style2{
    @media only screen and (min-width: 992px) and (max-width: 1199px){
        flex-direction: row;
    }
    & .product__showing--count{
        @media only screen and (min-width: 992px) and (max-width: 1199px){
            margin-top: 0;
        }
    }
}
.widget__filter--btn.style2{
    display: flex;
}
// End Shop page css here