/*
   13. Blog Details Css
*/

.blog__wrapper--sidebar {
  position: sticky;
  top: 0;
}
.blog__sidebar--widget {
  position: sticky;
  top: 0;
  &.left {
    @media only screen and (max-width: 991px) {
      margin-top: 8rem;
    }
    @media only screen and (max-width: 767px) {
      margin-top: 6rem;
    }
  }
}
.blog__post--header {
  @media only screen and (max-width: 575px) {
    text-align: center;
  }
  &.mb-30 {
    margin-bottom: 2.5rem;
  }
}

.post__header--title {
  @media only screen and (min-width: 992px) {
    line-height: 3.6rem;
    font-size: 3rem;
  }
  @media only screen and (min-width: 1366px) {
    line-height: 3.8rem;
    font-size: 3.2rem;
  }
  @media only screen and (min-width: 1600px) {
    line-height: 4.5rem;
    font-size: 3.7rem;
  }
}
.blog__post--meta {
  text-transform: capitalize;
  @media only screen and (min-width: 992px) {
    font-size: 1.5rem;
  }
  &__link {
    color: var(--secondary-color);
    &:hover {
      color: var(--secondary-color);
      text-decoration: underline;
    }
  }
}
.blog__thumbnail {
  line-height: 1;
  &.mb-30 {
    @media only screen and (max-width: 767px) {
      margin-bottom: 2rem;
    }
  }
}
.blog__details--content {
  @media only screen and (max-width: 575px) {
    text-align: center;
  }
  &__subtitle {
    line-height: 2.7rem;
    @media only screen and (min-width: 576px) {
      line-height: 3rem;
    }
    @media only screen and (min-width: 992px) {
      line-height: 3.2rem;
    }
    @media only screen and (min-width: 1200px) {
      line-height: 4rem;
      font-size: 2.7rem;
    }
  }
}

.blockquote__content {
  padding: 20px 30px;
  text-align: center;
  border-radius: 10px;
  @media only screen and (min-width: 992px) {
    padding: 30px;
  }
  @media only screen and (min-width: 1200px) {
    padding: 40px 50px;
  }
  @media only screen and (max-width: 767px) {
    margin: 0 0 2.5rem;
    padding: 1.5rem 2rem;
  }
  &--desc {
    font-size: 1.6rem;
    line-height: 3rem;
    font-style: italic;
    font-weight: 500;
    @media only screen and (min-width: 768px) {
      font-size: 1.8rem;
      line-height: 3.4rem;
    }
  }
}

.blog__tags--social__media {
  padding: 4.6rem 0 5.2rem;
  @media only screen and (min-width: 768px) {
    padding: 5rem 0;
  }
  @media only screen and (max-width: 1199px) {
    flex-direction: column;
    align-items: flex-start;
  }
  @media only screen and (max-width: 575px) {
    align-items: center;
  }
}
.blog__tags--media {
  @media only screen and (max-width: 1199px) {
    margin-bottom: 2rem;
  }
  @media only screen and (max-width: 575px) {
    flex-direction: column;
    align-items: flex-start;
  }
  &__title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-right: 1rem;
    @media only screen and (min-width: 992px) {
      font-size: 1.6rem;
    }
    @media only screen and (max-width: 575px) {
      margin-right: 0;
      margin-bottom: 1rem;
      width: 100%;
      text-align: center;
    }
  }
  &__list {
    margin-right: 0.7rem;
    &:last-child {
      margin-right: 0;
    }
  }
  &__link {
    border: 1px solid var(--border-color2);
    background: var(--white-color);
    padding: 0.5rem 1.2rem;
    line-height: 2.5rem;
    border-radius: 0.3rem;
    text-transform: capitalize;
    &:hover {
      background: var(--primary-color);
      border-color: var(--primary-color);
      color: var(--white-color);
    }
    @media only screen and (max-width: 575px) {
      padding: 0.4rem 0.8rem;
      font-size: 1.3rem;
    }
  }
}
.meta__deta {
  font-weight: 500;
  margin-top: 0.6rem;
  font-size: 1.5rem;
}

.blog__social--media {
  &__title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-right: 1rem;
    @media only screen and (min-width: 992px) {
      font-size: 1.6rem;
    }
  }
  &__list {
    margin-right: 1rem;
    &:last-child {
      margin-right: 0;
    }
  }
  &__link {
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--secondary-color);
    color: var(--white-color);
    border-radius: 50%;
    &:hover {
      background: var(--primary-color);
      color: var(--white-color);
    }
  }
}
.related__post--heading{
  &__maintitle{
    font-size: 2rem;
    @media only screen and (min-width: 992px) {
      font-size: 2.2rem;
      line-height: 2.8rem;
    }
  }
}
.related__post {
  &--items {
    &:hover {
      & .related__post--img {
        transform: scale(1.05);
      }
    }
  }
  &--thumbnail {
    overflow: hidden;
    & a{
      @media only screen and (max-width: 575px) {
        width: 100%;
      }
      & img{
        @media only screen and (max-width: 575px) {
          width: 100%;
        }
      }
    }
  }
  &--text{
    @media only screen and (max-width: 575px) {
      text-align: center;
     }
  }
  &--title {
    line-height: 2.2rem;
    @media only screen and (min-width: 992px) {
      line-height: 2.4rem;
    }
   
  }
  &--deta {
    font-size: 1.5rem;
  }
}

.comment__reply--btn {
  height: 3.4rem;
  line-height: 3.4rem;
  padding: 0 2rem;
  font-size: 1.4rem;
  @media only screen and (min-width: 768px) {
    font-size: 1.6rem;
    height: 4rem;
    line-height: 4rem;
    padding: 0 2.5rem;
  }
  @media only screen and (min-width: 992px) {
    font-size: 1.6rem;
  }
}

.reviews__comment--content {
  &__title2 {
    font-weight: 600;
    line-height: 2.4rem;
    margin-bottom: 0.6rem;
    @media only screen and (min-width: 992px) {
      font-size: 1.8rem;
    }
  }
  &__date2 {
    font-size: 1.5rem;
    color: var(--text-gray-color);
    margin-bottom: 0.8rem;
  }
}

.reviews__comment--reply__title{
  font-size: 2rem;
  @media only screen and (min-width: 992px) {
    font-size: 2.2rem;
    line-height: 2.8rem;
  }
}