/* 
    31. 404 page css 
*/
.error__content{
    &--img{
        @media only screen and (max-width: 1199px) {
            max-width: 600px;
         }
        @media only screen and (max-width: 767px) {
            max-width: 450px;
         }
        @media only screen and (max-width: 575px) {
            max-width: 100%;
            padding: 0 3rem;
         }
    }
    &--title{
        font-weight: 600;
        margin-bottom: 1.5rem;
        font-size: 2rem;
        line-height: 3rem;
        @media only screen and (min-width: 576px) {
            margin-bottom: 1.5rem;
            font-size: 2.6rem;
            line-height: 3rem;
        }
        @media only screen and (min-width: 768px) {
            margin-bottom: 1.8rem;
            font-size: 3.3rem;
            line-height: 4rem;
        }
        @media only screen and (min-width: 992px) {
           font-size: 4rem;
           line-height: 4.5rem;
           margin-bottom: 2rem;
        }
        @media only screen and (min-width: 1200px) {
           font-size: 4.5rem;
           line-height: 5rem;
           margin-bottom: 2.5rem;
        }
        @media only screen and (min-width: 1366px) {
           font-size: 5rem;
           line-height: 5.5rem;
        }
    }
    &--desc{
        font-size: 1.5rem;
        @media only screen and (min-width: 768px) {
            font-size: 1.7rem;
            line-height: 2.5rem;
            margin-bottom: 2.5rem;
         }
        @media only screen and (min-width: 992px) {
            font-size: 1.8rem;
            line-height: 2.5rem;
            margin-bottom: 2.5rem;
         }
        @media only screen and (min-width: 1200px) {
            font-size: 2rem;
            line-height: 2.5rem;
            margin-bottom: 3rem;
         }
    }
    &--btn{
        @media only screen and (min-width: 992px) {
            font-size: 1.6rem;
        }
    }
}