/*
   20. Compare Page Css
*/

.compare__heading--maintitle{
    font-size: 2rem;
    @media only screen and (min-width: 992px) {
        font-size: 3rem;
        height: 3.2rem;
      }
}
.compare__table{
    border: 1px solid var(--border-color2);
    border-spacing: 0;
    border-bottom: 0;
    &--items{
        &__child{
            padding: 1.2rem;
            border-left: 1px solid var(--border-color2);
            border-bottom: 1px solid var(--border-color2);
            text-align: center;
            &:first-child{
                border-left: 0;
            }
            @media only screen and (max-width: 991px) {
                min-width: 200px;
            }
            &--header{
                padding: 1rem;
                border-bottom: 1px solid var(--border-color2);
                text-transform: uppercase;
                background: var(--gray-color2);
                font-size: 1.3rem;
                @media only screen and (min-width: 1200px) {
                    font-size: 1.5rem;
                }
            }
        }
    }
}
.compare__product--title{
    font-size: 1.4rem;
    line-height: 2.4rem;
    margin-bottom: 1rem;
    text-align: center;
}
.compare__product{
    &--thumbnail{
        border-radius: 0.5rem;
    }
}
.compare__remove{
    padding: 0;
    float: right;
    width: 100%;
    border: 0;
    background: inherit;
    text-align: center;
    color: var(--text-gray-color);
    margin-bottom: 5px;
    &:hover{
        color: var(--secondary-color);
    }
}
.compare__description{
    color: var(--text-gray-color);
    padding: 0.5rem 0;
    font-size: 1.4rem;
    line-height: 2.6rem;
    text-align: center;
    @media only screen and (min-width: 1200px) {
        font-size: 1.5rem;
        line-height: 2.8rem;
    }
}
.compare__instock{
    text-transform: uppercase;
    font-size: 1.3rem;
    text-align: center;
}
.compare__product--price{
    color: var(--text-gray-color);
}

.compare__cart--btn{
    border-radius: 0.5rem;
    text-transform: uppercase;
    padding: 0 1.5rem;
    height: 3.8rem;
    line-height: 3.8rem;
    font-size: 1.3rem;
    @media only screen and (min-width: 768px) {
        padding: 0 2rem;
        height: 4rem;
        line-height: 4rem;
    }
    @media only screen and (min-width: 992px) {
        height: 4.2rem;
        line-height: 4.2rem;
        padding: 0 2.5rem;
    }
}