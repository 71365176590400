/*
   18. Cart Page Css
*/

.cart__table{
    &--inner{
        width: 100%;
        border-spacing: 0;
    }
    &--header{
        @media only screen and (max-width: 575px) {
            display: none;
        }
        &__list{
            padding: 0 2rem 2rem 0;
            text-transform: uppercase;
            text-align: left;
            border-bottom: 1px solid var(--border-color2);
            &:last-child{
                padding-right: 0;
            }
            @media only screen and (min-width: 992px) {
                font-size: 1.5rem;
            }
            &.text-center{
                text-align: center;
            }
            &.text-right{
                text-align: right;
            }
        }
    }
    &--body{
        &__items{
            @media only screen and (max-width: 575px) {
                display: flex;
                flex-direction: column;
            }
        }
        &__list{
            border-bottom: 1px solid var(--border-color2);
            padding: 2rem 2rem 2rem 0;
            &:last-child{
                padding-right: 0;
            }
            @media only screen and (max-width: 575px) {
                display: flex;
                justify-content: center;
                padding: 2rem 0;
            }
        }
    }
}

.cart__thumbnail{
    max-width: 10rem;
    line-height: 1;
    @media only screen and (max-width: 767px) {
        max-width: 8rem;
    }
}
.cart__content{
    padding-left: 1.5rem;
    @media only screen and (min-width: 1200px) {
        padding-left: 2rem;
    }
    &--variant{
        display: block;
        margin-bottom: 0.5rem;
        line-height: 2.2rem;
        font-size: 1.3rem;
        &:last-child{
            margin-bottom: 0;
        }
    }
    &--title{
        margin-bottom: 0.5rem;
        line-height: 2.5rem;
        font-size: 1.5rem;
        @media only screen and (min-width: 1200px) {
            font-size: 1.6rem;
        }

    }
}

.cart__remove--btn{
    font-weight: 600;
    width: 3rem;
    height: 3rem;
    text-align: center;
    background: var(--white-color);
    box-shadow: 0 2px 22px rgba(0, 0, 0, 0.16);
    margin-right: 1.5rem;
    border-radius: 50%;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover{
        color: var(--secondary-color);
    }
}
.cart__price{
    font-weight: 600;
    @media only screen and (min-width: 992px) {
        font-size: 1.6rem;
    }
}

.continue__shopping {
    padding-top: 2rem;
    &--link{
        font-size: 1.5rem;
        font-weight: 600;
        color: var(--secondary-color);
        @media only screen and (min-width: 992px) {
            font-size: 1.7rem;
        }
    }
    &--clear{
        font-size: 1.5rem;
        font-weight: 600;
        color: var(--secondary-color);
        border: 0;
        padding: 0;
        background: inherit;
        @media only screen and (min-width: 992px) {
            font-size: 1.7rem;
        }
    }
}

.cart__summary{
    box-shadow: 0 2px 22px rgba(0, 0, 0, 0.16);
    padding: 2rem;
    background: var(--white-color);
    position: sticky;
    top: 0;
    @media only screen and (max-width: 991px) {
        margin-top: 7.5rem;
    }
    @media only screen and (max-width: 767px) {
        margin-top: 5.5rem;
    }
    &--total{
        &__table{
            width: 100%;
        }
    } 
}

.coupon__code {
    &--title{
        font-size: 1.7rem;
        margin-bottom: 0.7rem;
        line-height: 2.4rem;
        @media only screen and (min-width: 992px) {
            font-size: 2rem;
        }
    }
    &--desc{
        margin-bottom: 1.5rem;
    }
    &--field{
        &__input{
            border: 1px solid var(--border-color);
            height: 4.5rem;
            padding: 0 1rem;
            width: 100px;
            @media only screen and (min-width: 768px) {
                width: 160px;
            }
            @media only screen and (min-width: 992px) {
                width: 140px;
            }
            @media only screen and (min-width: 1200px) {
                width: 160px;
            }
            @media only screen and (max-width: 575px) {
                height: 4rem;
            }
            &:focus{
                border-color: var(--secondary-color);
            }
        }
        &__btn{
            height: 4.5rem;
            line-height: 4.5rem;
            padding: 0 2.5rem;
            margin-left: 2rem;
            font-size: 1.4rem;
            @media only screen and (min-width: 992px) {
                font-size: 1.5rem;
            }
            @media only screen and (min-width: 1200px) {
                margin-left: 3rem;
            }
            @media only screen and (max-width: 575px) {
                height: 4rem;
                line-height: 4rem;
                padding: 0 1.8rem;
            }
        }
    }
}

.cart__note{
    &--title{
        font-size: 1.7rem;
        margin-bottom: 0.7rem;
        line-height: 2.4rem;
        @media only screen and (min-width: 992px) {
            font-size: 2rem;
        }
    }
    &--desc{
        margin-bottom: 1.5rem;
    }
    &--textarea{
        border: 1px solid var(--border-color);
        height: 10rem;
        padding: 1rem;
        width: 100%;
        resize: none;
        &:focus{
            border-color: var(--secondary-color);
        }
    }
}

.cart__summary--total{
    &__list{
        margin-bottom: 1rem;
        display: flex;
        justify-content: space-between;
        &:last-child{
            margin-bottom: 0;
        }
    }
}

.cart__summary--footer{
    &__desc{
        font-size: 1.5rem;
        margin-bottom: 1.5rem;
    }
    &__btn{
        height: 4.5rem;
        line-height: 4.5rem;
        padding: 0 1.5rem;
        @media only screen and (min-width: 992px) {
            padding: 0 1.5rem;
            font-size: 1.5rem;
        }
        @media only screen and (min-width: 1200px) {
            padding: 0 2.5rem;
        }
        @media only screen and (max-width: 575px) {
            height: 4rem;
            line-height: 4rem;
            padding: 0 1.2rem;
        }

    }
}


.wishlist__cart--btn{
    font-size: 1.4rem;
    @media only screen and (min-width: 992px) {
        font-size: 1.6rem;
    }
    @media only screen and (max-width: 767px) {
        padding: 0.5rem 0.4rem;
        height: auto;
        line-height: 2.4rem;
        border-radius: 5px;
        text-align: center;
        font-size: 1.3rem;
        min-width: 95px;
    }
}

