
/*
   21. Contact Page Css
*/

.contact__section--bg{
    background: url(../../img/banner/banner-bg5.webp);
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: cover;
}
.contact__heading{
     &--desc{
        width: 100%;
     }
}
.contact__content{
    @media only screen and (max-width: 575px) {
        text-align: center;
     }
}
.contact__form{
    background: var(--white-color);
    border-radius: 10px;
    box-shadow: 0 7px 20px rgba(0, 0, 0, 0.16);
    padding: 2rem;
    @media only screen and (min-width: 768px) {
        padding: 3rem;
     }
    &--title{
        font-weight: 600;
        &.mb-30{
            @media only screen and (max-width: 1199px) {
               margin-bottom: 2rem;
            }
        }
    }
    &--label{
        display: block;
        margin-bottom: 8px;
        &__star{
            color: var(--secondary-color);
        }
    }
    &--input{
        width: 100%;
        height: 4.5rem;
        padding: 5px 15px;
        border-radius: 8px;
        border: 1px solid var(--border-color);
        &:focus{
            border-color: var(--secondary-color) !important;
        }
    }
    &--textarea{
        width: 100%;
        height: 8rem;
        padding: 12px 15px;
        border-radius: 8px;
        resize: none;
        border: 1px solid var(--border-color);
        font-family: var(--font-poppins);
        &:focus{
            border-color: var(--secondary-color) !important;
        }
        @media only screen and (min-width: 992px) {
            height: 120px;
         }
        @media only screen and (min-width: 1200px) {
            height: 145px;
         }
         &.style2{
            height: 8rem;
            @media only screen and (min-width: 992px) {
                height: 120px;
             }
            @media only screen and (min-width: 1366px) {
                height: 150px;
             }
         }
    }
    &--btn{
        width: 100%;
        @media only screen and (min-width: 992px) {
            font-size: 1.7rem;
        }
    }
}

.contact__info{
    background: var(--secondary-color);
    padding: 2rem 1.5rem;
    @media only screen and (min-width: 480px) {
        padding: 2rem;
    }
    @media only screen and (min-width: 576px) {
        padding: 3rem;
    }
    @media only screen and (min-width: 768px) {
        padding: 4rem;
    }
    @media only screen and (min-width: 992px) {
        padding: 4rem 3rem;
    }
    @media only screen and (min-width: 1200px) {
        padding: 5rem 6rem;
    }
    @media only screen and (max-width: 991px) {
        margin-top: 3rem;
    }
    &--items{
        margin-bottom: 2.7rem;
        &:last-child{
            margin-bottom: 0;
        }
        @media only screen and (max-width: 575px) {
            margin-bottom: 2rem;
        }
    }
}

.contact__info--icon{
    margin-right: 1.2rem;
    color: var(--white-color);
    padding-top: 0.8rem;
    @media only screen and (min-width: 1200px) {
        padding-top: 0.8rem;
    }
    @media only screen and (max-width: 575px) {
        padding-top: 0.3rem;
    }
    & svg{
        @media only screen and (max-width: 575px) {
            width: 2.5rem;
        }
        @media only screen and (max-width: 479px) {
            width: 2rem;
        }
    }
}
.contact__info--content{
    &__title{
        font-weight: 600;
        @media only screen and (min-width: 992px) {
            font-size: 2rem;
            line-height: 2.8rem;
        }
    }
    &__desc{
        font-size: 1.5rem;
        line-height: 2.6rem;
        @media only screen and (min-width: 1200px) {
            font-size: 1.6rem;
            line-height: 2.7rem;
        }
        & a{
            &:hover{
                color: var(--primary-color);
            }
        }
    }
}
.contact__info--social{
    &__list{
        margin-right: 11px;
        &:last-child{
            margin-right: 0;
        }
    }
    &__icon{
        width: 3rem;
        height: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--white-color);
        color: var(--secondary-color);
        border-radius: 50%;
        &:hover{
            background: var(--primary-color);
            color: var(--white-color);
        }
    }
}
.contact__map--iframe{
    width: 100%;
    height: 200px;
    margin-bottom: -7px;
    @media only screen and (min-width: 576px) {
        height: 250px;
    }
    @media only screen and (min-width: 768px) {
        height: 300px;
    }
    @media only screen and (min-width: 992px) {
        height: 400px;
    }
    @media only screen and (min-width: 1200px) {
        height: 500px;
    }
    @media only screen and (min-width: 1600px) {
        height: 600px;
    }
}