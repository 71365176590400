/*
    2. Header CSS
*/

// Start offcanvas header menu css here
.offcanvas__header--menu__open {
  line-height: 1;
  display: none;
  @media only screen and (max-width: 991px) {
    display: block;
  }
  &--svg {
    width: 32px;
  }
  &--btn{
    & > * {
      pointer-events: none;
    }
  }
}

.offcanvas-header {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 300px;
  height: 100vh;
  transition: var(--transition);
  transform: translateX(-100%);
  background-color: var(--white-color);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  opacity: 0;
  visibility: hidden;
  @media only screen and (min-width: 480px) {
    max-width: 320px;
  }
}
.offcanvas-header.open {
  transform: translateX(0);
  opacity: 1;
  visibility: visible;
}
.offcanvas-header.open ~ .offcanvas-overlay {
  visibility: visible;
  opacity: 0.75;
}

.offcanvas-overlay {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  visibility: hidden;
  width: 100%;
  height: 100%;
  transition: var(--transition);
  opacity: 0;
  background-color: var(--black-color);
}

.offcanvas__inner {
  position: relative;
  height: 100%;
}

/* Offacnvas Logo */
.offcanvas__logo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 15px;
}
.offcanvas__close--btn {
  position: relative;
  align-self: center;
  width: 2rem;
  height: 2rem;
  padding: 0;
  text-indent: -9999px;
  border: none;
  background-color: transparent;
}
.offcanvas__close--btn::before,
.offcanvas__close--btn::after {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 2px;
  margin-top: -1px;
  content: "";
  transform: rotate(45deg);
  background-color: var(--black-color);
}
.offcanvas__close--btn::after {
  transform: rotate(-45deg);
}

/* Mobile Menu */
.offcanvas__menu {
  overflow-y: auto;
  height: 100%;
}

.offcanvas__menu_ul {
  overflow: auto;
  margin: 0;
  padding: 0;
  list-style: none;
}

.offcanvas__menu_li {
  position: relative;
  border-bottom: 1px solid var(--border-color2);
}
.offcanvas__menu_li:first-child {
  border-top: 1px solid var(--border-color2);
}

.offcanvas__menu_item {
  line-height: 1;
  display: block;
  padding: 15px 20px;
  text-transform: uppercase;
}

/* Mobile Sub Menu */
.offcanvas__sub_menu {
  display: none;
  margin: 0;
  padding: 0;
  list-style: none;
}

.offcanvas__sub_menu_li {
  position: relative;
  border-top: 1px solid var(--border-color2);
}

.offcanvas__sub_menu_item {
  line-height: 1;
  display: block;
  padding: 15px 0 15px 30px;
}
.offcanvas__sub_menu_item ~ .offcanvas__sub_menu .offcanvas__sub_menu_item {
  padding-left: 40px;
}

.offcanvas__sub_menu_toggle {
  font-size: 20px;
  position: absolute;
  z-index: 9;
  top: 0;
  right: 0;
  width: 4rem;
  height: 4.6rem;
  padding: 0;
  border: none;
  border-radius: 0;
  background-color: transparent;
}
.offcanvas__sub_menu_toggle::before,
.offcanvas__sub_menu_toggle::after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 12px;
  height: 2px;
  content: "";
  transition: var(--transition);
  transform: translateX(-50%) translateY(-50%);
  background-color: var(--black-color);
}
.offcanvas__sub_menu_toggle:not(.active)::after {
  transform: translateX(-50%) translateY(-50%) rotate(90deg);
}
.offcanvas__account--items {
  padding: 28px 17px 20px;
  &__icon {
    width: 3rem;
    height: 3rem;
    text-align: center;
    background: var(--secondary-color);
    line-height: 3.8rem;
    border-radius: 50%;
    color: var(--white-color);
  }
  &__label {
    font-size: 1.5rem;
    font-weight: 600;
    margin-left: 6px;
  }
}

.offcanvas__language--switcher {
  font-weight: 500;
  font-family: var(--font-poppins);
}
.offcanvas__dropdown--language {
  position: absolute;
  z-index: 9;
  width: 100px;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
  bottom: 100%;
  opacity: 0;
  visibility: hidden;
  margin-bottom: 20px;
  transition: var(--transition);
  background: var(--white-color);
  &.active {
    opacity: 1;
    visibility: visible;
    margin-bottom: 12px;
  }
}

.offcanvas__account--currency {
  position: relative;
  padding: 0 17px;
  &__menu {
    font-family: var(--font-poppins);
    font-weight: 500;
  }
  &__submenu {
    position: absolute;
    z-index: 9;
    width: 100px;
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
    bottom: 100%;
    opacity: 0;
    visibility: hidden;
    margin-bottom: 20px;
    transition: var(--transition);
    background: var(--white-color);
    &.active {
      opacity: 1;
      visibility: visible;
      margin-bottom: 12px;
    }
  }
}

// End offcanvas header menu css here

// Start Offcanvas stikcy toolbar css here
.offcanvas__stikcy--toolbar {
  position: fixed;
  bottom: 0;
  background: var(--white-color);
  left: 0;
  right: 0;
  z-index: 99;
  box-shadow: 0 0 9px rgba(0, 0, 0, 0.12);
  padding: 10px 20px;
  display: none;
  visibility: hidden;
  @media only screen and (min-width: 500px) {
    padding: 10px 40px;
  }
  @media only screen and (min-width: 768px) {
    padding: 10px 60px;
  }
  @media only screen and (max-width: 991px) {
    display: block;
    visibility: visible;
  }
  &__btn {
    position: relative;
    text-align: center;
    &:hover {
      & .offcanvas__stikcy--toolbar__icon {
        background: var(--primary-color);
        color: var(--white-color);
      }
    }
    & > *{
      pointer-events: none;
    }
  }
  &__icon {
    width: 3rem;
    height: 3rem;
    text-align: center;
    background: var(--secondary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: var(--white-color);
    margin: 0 auto;
  }
  &__label {
    display: block;
    font-size: 1.2rem;
    font-weight: 600;
    margin-top: 6px;
  }
}

// header sticky css here
.header__sticky {
  &.sticky {
    position: fixed;
    width: 100%;
    top: 0;
    background: var(--white-color);
    left: 0;
    z-index: 99;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.15);
    transition: 0.3s;
  }
}

// Start header topbar css here
.main__logo{
  @media only screen and (max-width: 991px) {
    margin-right: -30px;
  }
  @media only screen and (max-width: 575px) {
    margin-right: -20px;
  }
}
.main__logo--title {
  line-height: 2rem;
}
.main__logo--img{
  @media only screen and (max-width: 575px) {
    max-width: 120px;
  }
  @media only screen and (max-width: 400px) {
    max-width: 110px;
  }
}
.header__topbar {
  padding: 1.2rem 0;
  &--inner {
    @media only screen and (max-width: 991px) {
      justify-content: center;
    }
  }
}
.header__contact--info{
  &__list{
    margin-right: 2.5rem;
    @media only screen and (max-width: 479px) {
      margin-right: 1.2rem;
    }
    &:last-child{
      margin-right: 0;
    }
    font-size: 1.4rem;
  }
  &__icon{
    vertical-align: middle;
    margin-right: 0.3rem;
    @media only screen and (max-width: 479px) {
      margin-right: 0rem;
    }
  }
}
.header__discount--text {
  font-size: 1.6rem;
  @media only screen and (min-width: 992px) {
    font-size: 1.6rem;
  }
  @media only screen and (min-width: 1200px) {
    font-size: 1.8rem;
  }
}
.header__discount--icon__img {
  vertical-align: middle;
  margin-right: 0.4rem;
}
.language__currency {
  @media only screen and (max-width: 991px) {
    padding: 5px 20px;
  }
  &--list {
    position: relative;
    margin-right: 2rem;
    &:last-child {
      margin-right: 0;
    }
  }
}
.language__switcher {
  font-size: 2rem;
  @media only screen and (min-width: 992px) {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
  &--icon__img {
    vertical-align: middle;
    margin-right: 3px;
  }
  &.style2 {
    color: var(--text-gray-color);
    &:hover {
      color: var(--secondary-color2);
    }
  }
}
.dropdown__language {
  position: absolute;
  z-index: 9;
  width: 100px;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
  top: 100%;
  opacity: 0;
  right: 0;
  visibility: hidden;
  margin-top: 28px;
  transition: var(--transition);
  background: var(--white-color);
  &.active {
    opacity: 1;
    visibility: visible;
    margin-top: 17px;
  }
}
.language__items {
  &:last-child {
    & .language__text {
      border-bottom: 0;
    }
  }
}

.language__text {
  display: block;
  font-size: 1.4rem;
  padding: 10px 14px;
  background: var(--gray-color2);
  border-bottom: 1px solid var(--border-color2);
  &:hover {
    background: var(--primary-color);
    color: var(--white-color);
    border-color: var(--primary-color);
  }
}
.account__currency {
  margin-right: 25px;
  position: relative;
  @media only screen and (min-width: 1200px) {
    margin-right: 32px;
  }
  &--link {
    font-size: 1.5rem;
    line-height: 2rem;
    @media only screen and (min-width: 992px) {
      font-size: 1.5rem;
    }
    &.style2 {
      color: var(--text-gray-color);
      &:hover {
        color: var(--secondary-color2);
      }
    }
  }
}
.dropdown__currency {
  position: absolute;
  z-index: 9;
  width: 100px;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
  top: 100%;
  opacity: 0;
  right: 0;
  visibility: hidden;
  margin-top: 28px;
  transition: var(--transition);
  background: var(--white-color);
  &.active {
    opacity: 1;
    visibility: visible;
    margin-top: 17px;
  }
}

.currency__items {
  &:last-child {
    & .currency__text {
      border-bottom: 0;
    }
  }
}

.currency__text {
  display: block;
  font-size: 1.4rem;
  padding: 1rem 1.4rem;
  background: var(--gray-color2);
  border-bottom: 1px solid var(--border-color2);
  &:hover {
    background: var(--primary-color);
    color: var(--white-color);
    border-color: var(--primary-color);
  }
}

// End header topbar css here

.main__header {
  @media only screen and (max-width: 991px) {
    padding: 1.5rem 0;
  }
}

.header__account {
  &--items {
    margin-right: 2rem;
    &:last-child {
      margin-right: 0;
    }
    @media only screen and (max-width: 575px) {
      margin-right: 1rem;
    }
  }
  &--search__items {
    &.mobile__d--block {
      @media only screen and (min-width: 992px) {
        display: none;
      }
    }
  }
  &--btn {
    position: relative;
    color: var(--text-gray-color);
    text-align: center;
    &:hover {
      color: var(--secondary-color);
    }
    & > * {
      pointer-events: none;
    }
  }
}

.items__count {
  position: absolute;
  right: -1.4rem;
  top: -1rem;
  width: 2rem;
  height: 2rem;
  font-size: 1rem;
  line-height: 2rem;
  background: var(--secondary-color);
  text-align: center;
  border-radius: 50%;
  color: var(--white-color);
  &.style2 {
    right: -1.4rem;
  }
  &.wishlist__count {
    right: -0.7rem;
  }
}

.predictive__search {
  &--title {
    margin-bottom: 15px;
    @media only screen and (min-width: 992px) {
      margin-bottom: 20px;
      font-size: 2.7rem;
      line-height: 3rem;
    }
    @media only screen and (min-width: 1600px) {
      margin-bottom: 2.5rem;
      font-size: 3rem;
      line-height: 3.5rem;
    }
  }
  &--box {
    background: var(--white-color);
    box-shadow: 0 -4px 27px rgba(62, 70, 120, 0.16);
    position: relative;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    opacity: 0;
    visibility: hidden;
    z-index: 999;
    transition: all 0.3s ease 0s;
    transform: translateY(-100%);
    &.active {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }

    &__inner {
      padding: 20px 16px;
      text-align: center;
      @media only screen and (min-width: 576px) {
        padding: 22px 50px;
      }
      @media only screen and (min-width: 768px) {
        padding: 30px 80px;
      }
      @media only screen and (min-width: 992px) {
        padding: 30px 150px;
      }
    }
  }
  &--form {
    width: 100%;
    position: relative;
  }
  &--input {
    width: 100%;
    height: 5rem;
    border: 1px solid var(--border-color2);
    padding: 0 80px 0 15px;
    font-size: 1.6rem;
    font-weight: 400;
    border-radius: 5px;
    &:focus {
      border-color: var(--secondary-color);
    }
    &:focus::-webkit-input-placeholder {
      color: #000000;
    }
    @media only screen and (max-width: 576px) {
      height: 45px;
    }
  }
  &--button {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    padding: 0;
    background: var(--secondary-color);
    border: 0;
    width: 6rem;
    text-align: center;
    color: var(--white-color);
    border-radius: 0 5px 5px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background: var(--primary-color);
    }
  }
  &--close__btn {
    position: absolute;
    top: 20px;
    right: 40px;
    padding: 0;
    border: 0;
    background: inherit;
    @media only screen and (max-width: 576px) {
      right: 15px;
    }
    &:hover {
      color: var(--primary-color);
      transform: scale(1.3);
    }
    & > * {
      pointer-events: none;
    }
  }
}

.header__bottom {
  box-shadow: 0 3px 8px rgba(71, 71, 71, 0.16);
}

// Start main menu css here
.header__menu {
  &--items {
    margin-right: 3rem;
    position: relative;
    padding: 2rem 0;
    @media only screen and (min-width: 992px) {
      margin-right: 2.5rem;
    }
    @media only screen and (min-width: 1200px) {
      margin-right: 3.8rem;
      padding: 2.5rem 0;
    }
    @media only screen and (min-width: 1366px) {
      margin-right: 4rem;
    }
    @media only screen and (min-width: 1600px) {
      padding: 3rem 0;
    }
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      & .header__menu--link {
        color: var(--secondary-color);
        &::before{
          width: 100%;
          left: 0;
          right: 0;
        }
      }
      & .header__sub--menu {
        visibility: visible;
        margin-top: 0;
        opacity: 1;
      }
      & .header__mega--menu {
        visibility: visible;
        margin-top: 0;
        opacity: 1;
      }
    }
  }
  &--link {
    font-size: 1.6rem;
    line-height: 2.7rem;
    font-weight: 500;
    color: var(--text-gray-color);
    position: relative;
    @media only screen and (min-width: 1200px) {
      font-size: 1.8rem;
    }
    
    &::before{
      position: absolute;
      content: "";
      width: 0;
      height: 2px;
      left: 50%;
      right: 50%;
      bottom: 0;
      background: var(--secondary-color);
      transition: var(--transition);
    }
  }
}


.header__sub--menu {
  position: absolute;
  z-index: 99;
  top: 100%;
  left: 0;
  visibility: hidden;
  width: 220px;
  margin: 0;
  margin-top: 20px;
  padding: 20px;
  list-style: none;
  transition: var(--transition);
  opacity: 0;
  background-color: var(--white-color);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  &__link {
    font-size: 1.5rem;
    display: block;
    padding: 10px 0;
  }
}
.mega__menu--items {
  position: static;
}
.header__mega--menu {
  position: absolute;
  z-index: 99;
  top: 100%;
  left: 0;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0;
  margin-top: 20px;
  padding: 25px;
  list-style: none;
  transition: var(--transition);
  visibility: hidden;
  opacity: 0;
  background-color: var(--white-color);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  &__li {
    padding: 3px 15px;
    width: 20%;
    &.fullscreen__style{
      width: 100%;
      margin-top: 1.8rem;
    }
  }
}
.header__mega--subtitle {
  font-weight: 600;
  font-size: 1.6rem;
  margin-bottom: 15px;
}
.header__mega--sub__menu {
  &--title {
    font-size: 1.5rem;
    padding: 8px 0;
  }
}
.header__mega--menu__banner{
  overflow: hidden;
  border-radius: 10px;
  position: relative;
  margin-right: 2.5rem;
  &:last-child{
    margin-right: 0;
  }
  &:hover{
    & .header__mega--menu__banner--img{
      transform: scale(1.05);
    }
    &::before{
      opacity: 0.5;
    }
  }
  &::before{
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: var(--secondary-color);
    opacity: 0;
    left: 0;
    top: 0;
    pointer-events: none;
    z-index: 9;
    transition: var(--transition);
  }
  &--img{
    border-radius: 10px;
  }
}
// End main menu css here

// Start minicart css here
.offCanvas__minicart {
  position: fixed;
  width: 310px;
  height: 100%;
  padding: 18px 15px 30px;
  background: var(--white-color);
  z-index: 999;
  right: 0;
  top: 0;
  transition: 0.4s;
  transform: translateX(100%);
  overflow-y: auto;
  box-shadow: 0 0 15px rgba(5, 0, 0, 0.1);
  visibility: hidden;
  &.active {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
  }
  @media only screen and (min-width: 480px) {
    width: 375px;
  }
  @media only screen and (min-width: 1600px) {
    padding: 20px 15px 33px;
  }
}
.minicart__header {
  &--top {
    margin-bottom: 10px;
  }
  &--desc {
    font-size: 1.6rem;
    color: var(--text-gray-color);
    @media only screen and (min-width: 480px) {
      font-size: 1.7rem;
    }
  }
}
.minicart__title {
  font-weight: 600;
}
.minicart__close--btn {
  padding: 0;
  background: inherit;
  border: 0;
  line-height: 1.5rem;
  color: var(--black-color);
  &:hover {
    color: var(--primary-color);
    transform: scale(1.3);
  }
  & > * {
    pointer-events: none;
  }
}
.minicart__close--icon {
  width: 26px;
}
.minicart__product {
  margin-bottom: 17px;
  &--items {
    margin-top: 18px;
    padding-top: 18px;
    border-top: 1px solid var(--border-color2);
    @media only screen and (min-width: 1600px) {
      margin-top: 22px;
      padding-top: 22px;
    }
  }
  &--remove {
    border: 0;
    padding: 0;
    background: none;
    font-size: 1.2rem;
    text-transform: uppercase;
    text-decoration: underline;
    font-weight: 700;
    opacity: 0.7;
    &:hover {
      color: var(--secondary-color);
    }
  }
}
.minicart__thumbnail {
  width: 100px;
  line-height: 1;
  @media only screen and (min-width: 480px) {
    width: 120px;
  }
  & a{
    display: block;
    & img{
      display: block;
    }
  }
}
.minicart__text {
  width: calc(100% - 100px);
  padding-left: 10px;
  @media only screen and (min-width: 480px) {
    width: calc(100% - 120px);
    padding-left: 13px;
  }
}
.minicart__subtitle {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 2px;
  @media only screen and (min-width: 480px) {
    font-size: 1.6rem;
  }
}
.color__variant {
  opacity: 0.8;
  margin-bottom: 4px;
  line-height: 20px;
  font-size: 1.4rem;
}
.minicart__price {
  margin-bottom: 9px;
  & .current__price{
    font-size: 1.5rem;
  }
  & .old__price{
    font-size: 1.4rem;
  }
}

.minicart__quantity {
  margin-right: 15px;
}
.quantity__box {
  text-align: center;
  display: flex;
}
.quantity__value {
  display: inline-block;
  border: 1px solid var(--border-color2);
  margin: 0px;
  width: 3.3rem;
  height: 3rem;
  text-align: center;
  padding: 0;
  background: var(--gray-color2);
  cursor: pointer;
  font-size: 2rem;
  font-weight: 500;
  &.decrease {
    margin-right: -4px;
    border-radius: 13px 0 0 13px;
  }
  &.increase {
    margin-left: -4px;
    border-radius: 0 13px 13px 0;
  }
}

input.quantity__number {
  text-align: center;
  border: none;
  border-top: 1px solid var(--border-color2);
  border-bottom: 1px solid var(--border-color2);
  margin: 0px;
  width: 3.3rem;
  height: 3rem;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.minicart__amount {
  padding: 13px 0;
  border-top: 1px solid var(--border-color2);
  border-bottom: 1px solid var(--border-color2);
}
.minicart__amount_list {
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
}
.minicart__conditions {
  padding: 20px 0;
  &--input {
    vertical-align: middle;
  }
  &--label {
    font-weight: 600;
    letter-spacing: 0.5px;
    color: var(--text-gray-color);
  }
  &--link {
    color: var(--secondary-color);
    text-decoration: underline;
    &:hover {
      color: var(--primary-color);
      text-decoration: underline;
    }
  }
}
.minicart__button {
  &--link {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 4.5rem;
    height: 4.5rem;
    margin-right: 14px;
    &:last-child {
      margin-right: 0;
    }
  }
}

// End minicart css here

// home two css here
.main__header--style2{
  @media only screen and (max-width: 991px) {
    padding: 0 1.5rem;
  }
}

.main__logo--style2{
  background: #2a2a2a;
  padding: 0 2rem;
  height: 7rem;
  display: flex;
  align-items: center;
  @media only screen and (min-width: 1200px) {
    padding: 0 3rem;
    height: 7.8rem;
  }
  @media only screen and (min-width: 1366px) {
    padding: 0 4rem;
  }
  @media only screen and (min-width: 1600px) {
    padding: 0 7rem;
    height: 8.8rem;
  }
  @media only screen and (max-width: 991px) {
    margin-right: -50px;
  }
  @media only screen and (max-width: 575px) {
    margin-right: 0;
    padding: 0 1.5rem;
    height: 6rem;
  }
}

.header__contact--text{
  color: var(--white-color);
  background: var(--secondary-color);
  padding: 0 1.5rem;
  font-size: 1.5rem;
  text-transform: uppercase;
  height: 7rem;
  line-height: 7rem;
  &:hover{
    background: var(--primary-color);
    color: var(--white-color);
  }
  @media only screen and (min-width: 1200px) {
    padding: 0 2.5rem;
    font-size: 1.6rem;
    height: 7.8rem;
    line-height: 7.8rem;
  }
  @media only screen and (min-width: 1366px) {
    padding: 0 3rem;
    font-size: 1.8rem;
  }
  @media only screen and (min-width: 1600px) {
    padding: 0 5rem;
    height: 8.8rem;
  }
  @media only screen and (max-width: 991px) {
    display: none;
  }
}

.search__open--btn.style2{
  width: 3.5rem;
  height: 3.5rem;
  background: #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.minicart__open--btn.style2{
  display: flex;
  align-items: center;
}
.minicart__open--btn__style2{
  width: 3.5rem;
  height: 3.5rem;
  background: #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.minicart__items--count2{
  left: 25px;
}

.minicart__open--price__count{
  font-size: 1.8rem;
  font-weight: 500;
  color: #767676;
  margin-left: 1.4rem;
  @media only screen and (max-width: 1199px) {
    display: none;
  }
}


.header__menu {
  &--items {
    &.style2{
      margin-right: 2rem;
      @media only screen and (min-width: 992px) {
        margin-right: 2.3rem;
      }
      @media only screen and (min-width: 1200px) {
        margin-right: 3rem;
      }
      @media only screen and (min-width: 1366px) {
        margin-right: 4rem;
      }
      &:last-child {
        margin-right: 0;
      }
    }
    
  }
  &--link {
    &.style2{
      font-size: 1.5rem;
      @media only screen and (min-width: 1200px) {
        font-size: 1.6rem;
      }
      @media only screen and (min-width: 1366px) {
        font-size: 1.8rem;
      }
    }
    
  }
}


// home three css here
.header__contact--info.style3{
  @media only screen and (max-width: 479px) {
    flex-direction: column;
  }
  & .header__contact--info__list{
    @media only screen and (max-width: 479px) {
      margin-right: 0;
      margin-bottom: 1.5rem;
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
}
.header__social{
  margin-left: 3rem;
  @media only screen and (max-width: 767px) {
   display: none;
  }
  &--list{
    margin-right: 1.5rem;
    &:last-child{
      margin-right: 0;
    }
    &__icon{
      color: var(--white-color);
      &:hover{
        color: var(--primary-color);
      }
    }
  }
}