/* 
    33. Quickview css 
*/
.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    background: rgba(0, 0, 0, 0.8);
    cursor: pointer;
    visibility: hidden;
    opacity: 0;
    transition: all 0.35s ease-in;
    z-index: 99;
  }
  
  .modal.is-visible {
    visibility: visible;
    opacity: 1;
    & .quickview__main--wrapper{
      transform: translateY(0);
    }
  }

  .quickview__main--wrapper{
    position: relative;
    max-width: 880px;
    max-height: 80vh;
    border-radius: 10px;
    background: #fff;
    overflow: auto;
    cursor: default;
    padding: 25px;
    transform: translateY(-50px);
    transition: var(--transition);
    @media only screen and (max-width: 767px) {
      max-width: 550px;
    }
    @media only screen and (max-width: 575px) {
      padding: 25px 15px;
    }
  }
  
  .quickview__header{
    position: absolute;
    padding: 0;
    top: 16px;
    right: 16px;
    z-index: 9;
    @media only screen and (max-width: 767px) {
      top: 10px;
      right: 10px;
    }
  }

  .quickview__close--btn{
    font-size: 1.8rem;
    padding: 0;
    width: 3.2rem;
    height: 3.2rem;
    border-radius: 50%;
    font-weight: 700;
    border: 1px solid var(--border-color);
    background: var(--white-color);
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover{
        background: var(--secondary-color);
        border-color: var(--secondary-color);
        color: var(--white-color);
    }
  }
  

/* ANIMATIONS
–––––––––––––––––––––––––––––––––––––––––––––––––– */
[data-animation] .modal-dialog {
  opacity: 0;
  transition: all 0.5s cubic-bezier(0.51, 0.92, 0.24, 1.15);
}

[data-animation].is-visible .modal-dialog {
  opacity: 1;
  transition-delay: 0.2s;
}

//   quickview body css here
.quickview__gallery{
  @media only screen and (max-width: 767px) {
    margin-bottom: 20px;
  }
}
.quickview__thumb{
    position: relative;
    &--link{
        width: 100%;
        border-radius: 8px;
        display: block;
    }
    &--img{
        width: 100%;
        border-radius: 8px;
        display: block;
    }
}

.quickview__gallery--pagination{
  bottom: 5px !important;
  & .swiper-pagination-bullet{
      width: 2rem;
      height: 0.6rem;
      background: var(--theme-color);
      opacity: 1;
      border-radius: 2px;
      margin: 0 3px !important;
      &.swiper-pagination-bullet-active{
        background: var(--secondary-color);
      }
  }
}

.quickview__variant--wishlist__svg{
    width: 22px;
    margin-right: 7px;
}

.quickview__value--quantity{
    width: 3.5rem;
    height: 3.5rem;
    font-size: 2.2rem;
    line-height: 3.3rem;
    &.decrease{
        border-radius: 17px 0 0 17px    ;
    }
    &.increase{
        border-radius: 0 17px 17px 0;
    }
}
input.quickview__value--number{
    height: 3.5rem;
    font-size: 1.7rem;
}
.quickview__variant--wishlist__svg{
  width: 22px;
  margin-right: 4px;
}
.variant__wishlist{
  &--icon{
      margin-left: 1.8rem;
      display: flex;
      align-items: center;
      @media only screen and (max-width: 991px) {
          margin-left: 1rem;
          font-size: 1.4rem;
      }
      @media only screen and (max-width: 479px) {
          display: block;
          margin-left: 0;
          margin-top: 1rem;
      }
  }
}
.quickview__value--quantity{
  width: 3.5rem;
  height: 3.5rem;
  font-size: 2.2rem;
  line-height: 3.3rem;
  &.decrease{
      border-radius: 17px 0 0 17px    ;
  }
  &.increase{
      border-radius: 0 17px 17px 0;
  }
}
input.quickview__value--number{
  height: 3.5rem;
  font-size: 1.7rem;
}
.quickview__cart--btn{
  height: 3.8rem;
  line-height: 3.8rem;
  padding: 0 17px;
  margin-left: 12px;
  font-size: 1.4rem;
  @media only screen and (min-width: 992px) {
    padding: 0 25px;
    margin-left: 15px;
    font-size: 1.5rem;
  }

}
.quickview__social{
  &--title{
      font-size: 1.5rem;
      font-weight: 600;
      margin-right: 15px;
  }
  &--list{
      margin-right: 10px;
      &:last-child{
          margin-right: 0;
      }
  }
  &--icon{
      width: 3rem;
      height: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--secondary-color);
      color: var(--white-color);
      border-radius: 50%;
      &:hover{
          background: var(--primary-color);
          color: var(--white-color);
      }
  }
}

.quickview__variant--wishlist__icon {
  display: flex;
  align-items: center;
}
