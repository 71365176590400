/*
   11. Team css
*/
.team__card{
    box-shadow: 0 0 36px rgba(0, 0, 0, 0.16);
    &:hover{
        & .team__card--thumbnail{
            &::before{
                opacity: 0.6;
            }
            & img{
                transform: scale(1.05);
            }
        }
    }
    &--thumbnail{
        position: relative;
        overflow: hidden;
        &::before{
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            background: var(--secondary-color);
            left: 0;
            right: 0;
            opacity: 0;
            z-index: 9;
            pointer-events: none;
            transition: var(--transition);
        }
        &__img{
            width: 100%;
            @media only screen and (max-width: 575px) {
                width: 100%;
            }
        }
    }
    &--content{
        padding: 2rem 2.2rem;
        @media only screen and (min-width: 1200px) {
            padding: 2.5rem 3rem;
        }
        &__info{
            margin-top: 1.5rem;
            &--list{
                color: var(--text-gray-color);
                line-height: 2.6rem;
                margin-bottom: 0.5rem;
                &:last-child{
                    margin-bottom: 0;
                }
            }
            &--icon{
                color: var(--secondary-color);
                margin-right: 0.3rem;
            }

        }
    }
}

.team__section--bg{
    background: #F9F9FB;
}