/*
   9. Product details css
*/
.product__details--media{
    position: sticky;
    top: 100px;
    @media only screen and (max-width: 767px) {
        margin-bottom: 5.5rem;
    }
}
.product__media{
    &--right{
        width: calc(100% - 75px);
        padding-left: 1.7rem;
        @media only screen and (min-width:350px) {
            width: calc(100% - 90px);
        }
        @media only screen and (min-width:395px) {
            width: calc(100% - 100px);
        }
        @media only screen and (min-width:425px) {
            width: calc(100% - 110px);
        }
        @media only screen and (min-width:455px) {
            width: calc(100% - 118px);
        }
        @media only screen and (min-width:480px) {
            width: calc(100% - 90px);
            padding-left: 2rem;
        }
        @media only screen and (min-width:515px) {
            width: calc(100% - 108px);
            padding-left: 2rem;
        }
        @media only screen and (min-width:576px) {
            width: calc(100% - 100px);
            padding-left: 2rem;
        }
        @media only screen and (min-width:768px) {
            width: calc(100% - 75px);
            padding-left: 3rem;
        }
        @media only screen and (min-width:992px) {
            width: calc(100% - 85px);
        }
        @media only screen and (min-width:1200px) {
            width: calc(100% - 116px);
        }
    }
    &--preview{
        &__items{
            position: relative;
            &--link{
                width: 100%;
                display: block;
            }
            &--img{
                width: 100%;
                display: block;
            }
        }
    }
    &--view__icon{
        position: absolute;
        bottom: 2rem;
        right: 2rem;
        &.media__play{
            right: 2rem;
            top: 2rem;
            bottom: auto;
        }
        &--link{
            width: 3.5rem;
            height: 3.5rem;
            text-align: center;
            background: var(--primary-color);
            border-radius: 2px;
            color: var(--white-color);
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover{
                background: var(--secondary-color);
                color: var(--white-color);
            }
            @media only screen and (min-width:768px) {
                width: 4rem;
                height: 4rem;
            }
        }
    }
}
.product__badge {
    position: absolute;
    top: 0;
    left: 0;
    &--items {
        width: 5rem;
        height: 2.3rem;
        background: var(--secondary-color);
        color: var(--white-color);
        font-size: 1.1rem;
        line-height: 2.3rem;
        text-align: center;
        @media only screen and (min-width: 576px) {
            width: 5.2rem;
            height: 2.5rem;
            font-size: 1.2rem;
            line-height: 2.5rem;
        }
       @media only screen and (min-width:768px) {
            font-size: 1.5rem;
            width: 6rem;
            height: 3rem;
            line-height: 3rem;
        }
    }
  }
.media__play--icon__link{
    color: var(--secondary-color);
    &:hover{
        color: var(--primary-color);
    }
}
.product__media--nav{
    width: 75px;
    @media only screen and (min-width:350px) {
        width: 90px;
    }
    @media only screen and (min-width:395px) {
        width: 100px;
    }
    @media only screen and (min-width:425px) {
        width: 110px;
    }
    @media only screen and (min-width:455px) {
        width: 118px;
    }
    @media only screen and (min-width:480px) {
        width: 90px;
    }
    @media only screen and (min-width:515px) {
        width: 108px;
    }
    @media only screen and (min-width:576px) {
        width: 100px;
    }
    @media only screen and (min-width:768px) {
        width: 75px;
    }
    @media only screen and (min-width:992px) {
        width: 85px;
    }
    @media only screen and (min-width:1200px) {
        width: 116px;
    }
    &__items{
        &--img{
            width: 100%;
           cursor: pointer;
           display: block;
        }
    }
}

// product details info css here
.product__details--info{
    @media only screen and (min-width:768px) {
        font-size: 1.6rem;
    }
    &__price{
        & .current__price{
            font-size: 1.6rem;
            font-weight: 600;
            @media only screen and (min-width: 992px) {
               font-size: 2rem;
            }
        }
        & .old__price{
            font-size: 1.4rem;
            margin-left: 0.8rem;
            font-weight: 600;
            @media only screen and (min-width: 992px) {
               font-size: 1.8rem;
            }
        }
    }
}
.variant__buy--now__btn{
    width: 100%;
    height: 3.8rem;
    line-height: 3.8rem;
    @media only screen and (min-width: 480px) {
        height: 4rem;
        line-height: 4rem;
    }
    @media only screen and (min-width: 768px) {
        height: 4.2rem;
        line-height: 4.2rem;
    }
    @media only screen and (min-width: 1200px) {
        height: 4.8rem;
        line-height: 4.8rem;
    }
}

.product__details--info__meta{
    &--list{
        font-size: 1.5rem;
        margin-bottom: 0.8rem;
        &:last-child{
            margin-bottom: 0;
        }
    }
}

.guarantee__safe--checkout{
    &__title{
        font-size: 1.5rem;
        font-weight: 600;
        margin-bottom: 1.2rem;
    }
}
.product__variant--list.quantity{
    @media only screen and (max-width: 479px) {
        flex-wrap: wrap;
    }
}
.variant__color{
    &--value{
        width: 4rem;
        height: 4rem;
        display: flex;
        border-radius: 3px;
        margin-right: 10px;
        line-height: 1;
        cursor: pointer;
        &:last-child{
            margin-right: 0;
        }
        &__img{
            border-radius: 3px;
            display: block;
        }
    }
}
.variant__color--list input[type=radio]+label{
  border: 1px solid var(--border-color);
  &:hover{
    border: 1px solid var(--secondary-color);
  }
}
.variant__color--list{
  & input[type=radio]{
    clip: rect(0,0,0,0);
    overflow: hidden;
    position: absolute;
    height: 1px;
    width: 1px;
    &:checked+label{
      border: 1px solid var(--secondary-color);
    }
  }
  margin-right: 10px;
  &:last-child{
    margin-right: 0;
  }
}
.variant__input--fieldset{
  min-width: fit-content;
  border: none;
  margin: 0;
  padding: 0;
}
.variant__size--list input[type=radio]+label{
  border: 1px solid var(--border-color2);
  &:hover{
    border: 1px solid var(--secondary-color);
    color: var(--secondary-color);
  }
}
.variant__size{
  &--list{
      margin-right: 13px;
      &:last-child{
          margin-right: 0;
      }
      & input[type=radio]{
        clip: rect(0,0,0,0);
        overflow: hidden;
        position: absolute;
        height: 1px;
        width: 1px;
        &:checked+label{
          border: 1px solid var(--secondary-color);
          color: var(--secondary-color);
        }
      }
  }
  
  &--value{
      width: 4.7rem;
      height: 3.2rem;
      line-height: 3.2rem;
      display: inline-block;
      border-radius: 5px;
      text-align: center;
      font-size: 1.5rem;
      cursor: pointer; 
  }
  
}


// product details tab section css here

.product__details--tab__section{
    background: var(--bg-gray-color);
    &.sidebar__tab--section{
        padding: 1.5rem;
        @media only screen and (min-width:768px) {
            padding: 2.5rem;
        }
        @media only screen and (min-width:1200px) {
            padding: 3.5rem;
        }
    }
}

.sidebar__tab--section{
    & .product__details--tab__list{
        @media only screen and (min-width: 992px) and (max-width: 1199px){
            margin-right: 3rem;
            &:last-child{
                margin-right: 0;
            }
        }
    }
}
.product__details--tab__inner{
    background: var(--white-color);
    padding: 1.5rem 1.2rem;
    @media only screen and (min-width:480px) {
        padding: 1.5rem;
    }
    @media only screen and (min-width:768px) {
        padding: 2rem;
    }
    @media only screen and (min-width:992px) {
        padding: 3rem;
    }
}
.product__details--tab{
    @media only screen and (max-width: 767px) {
        flex-wrap: wrap;
        &.mb-30{
            margin-bottom: 1.5rem;
        }
    }
    @media only screen and (max-width: 575px) {
        justify-content: center;
    }
    &__list{
        font-size: 1.6rem;
        line-height: 2.6rem;
        margin-right: 5rem;
        cursor: pointer;
        transition: var(--transition);
        position: relative;
        padding-bottom: 0.4rem;
        &::before{
            position: absolute;
            content: "";
            width: 0;
            height: 1px;
            background: var(--secondary-color);
            left: 50%;
            right: 50%;
            transition: var(--transition);
            bottom: 0;

        }
        &.active{
            color: var(--secondary-color);
            &::before{
                width: 100%;
                left: 0;
                right: 0;
            }
        }
        &:hover{
            color: var(--secondary-color);
            &::before{
                width: 100%;
                left: 0;
                right: 0;
            }
        }
        &:last-child{
            margin-right: 0;
        }
        @media only screen and (min-width: 768px) {
            font-size: 1.7rem;
        }
        @media only screen and (min-width: 1200px) {
            font-size: 1.8rem;
        }
        @media only screen and (max-width: 767px) {
            margin: 0 1rem 1.3rem;
        }
    }
}
.product__tab--content{
    &__title{
        font-weight: 600;
        font-size: 1.7rem;
        @media only screen and (min-width: 992px) {
            font-size: 1.8rem;
        }
    }
    &__desc{
        line-height: 2.6rem;
        color: var(--text-gray-color);
        @media only screen and (min-width: 992px) {
            font-size: 1.5rem;
            line-height: 2.8rem;
        }
    }

}
.product__tab--content__step.style2{
    @media only screen and (max-width: 767px) {
        flex-direction: column;
    }
}
.product__tab--content__banner{
    width: 100%;
    @media only screen and (min-width: 768px) {
        width: 310px;
    }
    @media only screen and (min-width: 992px) {
        width: 410px;
    }
    @media only screen and (min-width: 1200px) {
        width: 460px;
    }
}
.product__tab--content__right{
    width: 100%;
    @media only screen and (min-width: 768px) {
        width: calc(100% - 300px);
        padding-left: 2.5rem;
    }
    @media only screen and (min-width: 992px) {
        width: calc(100% - 410px);
    }
    @media only screen and (min-width: 1200px) {
        width: calc(100% - 460px);
    }
    @media only screen and (max-width: 767px) {
        padding-top: 2rem;
    }
}
.image__with--text__percent--list{
    &.three{
        &::after{
            width: 58%;
        }
    }
    &.four{
        &::after{
            width: 69%;
        }
    }
}
.product__tab--percent__style{
    & .image__with--text__percent--list{
        width: 100%;
        &:last-child{
            margin-top: 0;
        }
        @media only screen and (min-width: 768px) {
            width: 85%;
        }
        @media only screen and (min-width: 1200px) {
            width: 80%;
        }
    }
}
.product__reviews{
    &--header{
        position: relative;
        border-bottom: 1px solid var(--border-color2);
        padding-bottom: 1.5rem;
        margin-bottom: 2.5rem;
        @media only screen and (min-width: 768px) {
            padding-bottom: 2.5rem;
            margin-bottom: 3.5rem;
        }
        @media only screen and (max-width: 575px) {
            text-align: center;
        }
    }
}
.reviews__ratting{
    @media only screen and (max-width: 575px) {
        justify-content: center;
    }
    &--list{
        margin-right: 0.4rem;
        &:last-child{
            margin-right: 0;
        }
    }
    &--icon{
        color: var(--yellow-color);
    }
}
.reviews__summary--caption{
    color: var(--secondary-color);
    margin-left: 1.2rem;
}
.actions__newreviews--btn{
    position: absolute;
    right: 0;
    bottom: 2.2rem;
    @media only screen and (max-width: 575px) {
        position: inherit;
        bottom: inherit;
        margin-top: 1rem;
    }
}

.reviews__comment{
    &--area{
        padding: 0 2rem;
        margin-bottom: 3rem;
        @media only screen and (max-width: 767px) {
            padding: 0;
        }
    }
    &--thumb{
        width: 85px;
        @media only screen and (max-width: 575px) {
            margin-bottom: 1rem;
        }
        & img{
            display: block;
        }
    }
    &--top{
        margin-bottom: 1rem;
    }
    &--content{
        width: 100%;
        position: relative;
        @media only screen and (min-width: 576px) {
            width: calc(100% - 85px);
            padding-left: 1.5rem;
        }
        @media only screen and (min-width: 768px) {
            padding-left: 2.5rem;
        }
        &__date{
            border: 1px solid var(--border-color);
            padding: 0 1rem;
            border-radius: 5px;
            color: var(--text-gray-color);
            height: 3.8rem;
            line-height: 3.8rem;
            font-size: 1.4rem;
            @media only screen and (min-width: 768px) {
                height: 4.2rem;
                line-height: 4rem;
                font-size: 1.5rem;
            }
            @media only screen and (max-width: 479px) {
                padding: 0 0.7rem;
                font-size: 1.2rem;
                height: 3.6rem;
                line-height: 3.6rem;

            }
        }
        &__title{
            font-weight: 600;
            margin-bottom: 0.8rem;
        }
        &__desc{
            margin-bottom: 0;
            @media only screen and (min-width: 992px) {
                font-size: 1.5rem;
                line-height: 2.8rem;
            }
        }
        & .reviews__ratting{
            @media only screen and (max-width: 575px) {
               justify-content: flex-start;
            }
        }
    }
    &--list{
        border-bottom: 1px solid var(--border-color2);
        padding-bottom: 2rem;
        margin-bottom: 2rem;
        &:last-child{
            padding-bottom: 0;
            margin-bottom: 0;
            border-bottom: 0;
        }
        @media only screen and (min-width: 768px) {
            padding-bottom: 2.5rem;
            margin-bottom: 2.5rem;
        }
        @media only screen and (max-width: 575px) {
            flex-direction: column;
        }
        &.margin__left{
            margin-left: 2.2rem;
            @media only screen and (min-width: 400px) {
                margin-left: 2.5rem;
            }
            @media only screen and (min-width: 480px) {
                margin-left: 3rem;
            }
            @media only screen and (min-width: 992px) {
                margin-left: 5rem;
            }
        }
    }
    
}

.reviews__comment--reply{
    &__title{
        @media only screen and (max-width: 575px) {
            text-align: center;
        }
    }
    &__textarea{
        width: 100%;
        height: 100px;
        resize: none;
        padding: 1.2rem 1.5rem;
        border: 1px solid var(--border-color2);
        border-radius: 0.5rem;
        @media only screen and (min-width: 768px) {
            height: 150px;
        }
        @media only screen and (min-width: 992px) {
            font-size: 1.5rem;
        }
        &:focus{
            border-color: var(--secondary-color);
        }
    }
    &__input{
        width: 100%;
        border: 1px solid var(--border-color2);
        height: 5rem;
        border-radius: 0.5rem;
        padding: 0 1.5rem;
        @media only screen and (min-width: 992px) {
            font-size: 1.5rem;
        }
        &:focus{
            border-color: var(--secondary-color);
        }
    }
}



.product__details--gallery{
    position: sticky;
    top: 100px;
    @media only screen and (max-width: 767px) {
        margin-bottom: 54px;
    }
}

.product__media--nav.sidebar__style{
    @media only screen and (min-width: 992px) {
        width: 115px;
    }
    @media only screen and (min-width: 1200px) {
        width: 85px;
    }
    @media only screen and (min-width: 1366px) {
        width: 95px;
    }
    @media only screen and (min-width: 1600px) {
        width: 116px;
    }
}

.product__media--right.sidebar__style{
    @media only screen and (min-width: 992px) {
        width: calc(100% - 115px);
    }
    @media only screen and (min-width: 1200px) {
        width: calc(100% - 85px);
    }
    @media only screen and (min-width: 1366px) {
        width: calc(100% - 95px);
    }
    @media only screen and (min-width: 1600px) {
        width: calc(100% - 116px);
    }
}

.product__tab--content__step.style2.product__sidebar--style2{
    @media only screen and (max-width: 1365px) {
        flex-direction: column;
    }
}
.product__tab--content__banner.sidebar__style{
    @media only screen and (max-width: 1365px) {
       width: 100%;
       margin-bottom: 2.2rem;
    }
}
.product__tab--content__right.sidebar__style{
    @media only screen and (max-width: 1365px) {
        width: 100%;
        padding-left: 0;
     }
}

.product__info--sidebar__style{
    @media only screen and (min-width: 992px) and (max-width: 1199px){
        margin-top: 3rem;
    }
}

.product__media--right__horizontal{
    margin-bottom: 2rem;
}

.product__sidebar--widget{
    position: sticky;
    top: 100px;
    @media only screen and (max-width: 991px) {
        margin-top: 70px;
     }
    @media only screen and (max-width: 767px) {
        margin-top: 60px;
     }
}