
/*
    5. About CSS
*/
.image__with--text__section {
    position: relative;
  }
  
  @media only screen and (min-width: 992px) {
    .image__with--text__thumbnail {
      padding-right: 2rem;
    }
  }
  
  @media only screen and (min-width: 1200px) {
    .image__with--text__thumbnail {
      padding-right: 3rem;
    }
  }
  
  @media only screen and (min-width: 1600px) {
    .image__with--text__thumbnail {
      padding-right: 4.5rem;
    }
  }
  
  @media only screen and (max-width: 767px) {
    .image__with--text__thumbnail {
      margin-bottom: 3rem;
      text-align: center;
    }
  }
  
  .image__with--text__position--shape {
    position: absolute;
    top: 0;
    left: 3.5rem;
    z-index: -1;
  }
  
  @media only screen and (min-width: 1366px) and (max-width: 1599px) {
    .image__with--text__position--shape {
      max-width: 640px;
    }
  }
  
  @media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .image__with--text__position--shape {
      max-width: 570px;
    }
  }
  
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .image__with--text__position--shape {
      max-width: 450px;
    }
  }
  
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .image__with--text__position--shape {
      max-width: 400px;
    }
  }
  
  @media only screen and (max-width: 767px) {
    .image__with--text__position--shape {
      width: auto;
      left: 0;
    }
  }
  
  @media only screen and (max-width: 767px) {
    .image__with--text__content {
      text-align: center;
    }
  }
  
  @media only screen and (max-width: 767px) {
    .image__with--text__content--footer {
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
    }
  }
  
  @media only screen and (max-width: 479px) {
    .image__with--text__content--footer {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
    }
  }
  
  .image__with--text__content--footer__desc {
    padding-left: 2rem;
  }
  
  @media only screen and (min-width: 992px) {
    .image__with--text__content--footer__desc {
      padding-left: 3rem;
    }
  }
  
  @media only screen and (max-width: 767px) {
    .image__with--text__content--footer__desc {
      text-align: left;
    }
  }
  
  @media only screen and (max-width: 479px) {
    .image__with--text__content--footer__desc {
      padding-left: 0;
      padding-top: 2rem;
      text-align: center;
    }
  }
  
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .image__with--text__content--footer img {
      max-height: 80px;
    }
  }
  
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .image__with--text__content--footer img {
      max-height: 50px;
      max-width: 90px;
    }
  }
  
  @media only screen and (max-width: 767px) {
    .image__with--text__content--footer img {
      max-width: 100px;
      max-height: 52px;
    }
  }
  
  @media only screen and (max-width: 479px) {
    .image__with--text__content--footer img {
      margin: 0 auto;
    }
  }
  
  .image__with--text__percent--list {
    padding-bottom: 1rem;
    position: relative;
    width: 75%;
  }
  
  @media only screen and (min-width: 768px) {
    .image__with--text__percent--list {
      padding-bottom: 1.2rem;
    }
  }
  
  @media only screen and (max-width: 767px) {
    .image__with--text__percent--list {
      width: 90%;
      margin: 0 auto 1.8rem;
    }
    .image__with--text__percent--list:last-child {
      margin-bottom: 0;
    }
  }
  
  @media only screen and (max-width: 479px) {
    .image__with--text__percent--list {
      width: 100%;
    }
  }
  
  .image__with--text__percent--list::before {
    position: absolute;
    content: "";
    background: var(--border-color);
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
  }
  
  .image__with--text__percent--list::after {
    position: absolute;
    content: "";
    background: var(--secondary-color);
    bottom: 0;
    left: 0;
    width: 58%;
    height: 4px;
  }
  
  .image__with--text__percent--list.two::after {
    width: 77%;
  }
  
  .image__with--text__percent--content {
    font-size: 1.5rem;
    line-height: 2.4rem;
    font-weight: 500;
    color: var(--primary-color);
  }
  
  @media only screen and (min-width: 576px) {
    .image__with--text__percent--content {
      font-size: 1.6rem;
    }
  }
  
  @media only screen and (min-width: 768px) {
    .image__with--text__percent--content {
      font-size: 1.7rem;
    }
  }
  
  @media only screen and (max-width: 767px) {
    .about__section .row {
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
          -ms-flex-direction: column-reverse;
              flex-direction: column-reverse;
    }
  }
  
  @media only screen and (max-width: 767px) {
    .about__content {
      margin-top: 3rem;
    }
  }
  
  .about__content--desc.style2 {
    font-weight: 500;
  }
  
  .about__content--desc__list {
    color: var(--text-gray-color);
    line-height: 2.6rem;
    position: relative;
    padding-left: 1.7rem;
    margin-bottom: 0.5rem;
  }
  
  .about__content--desc__list:last-child {
    margin-bottom: 0;
  }
  
  .about__content--desc__list::before {
    position: absolute;
    content: "";
    width: 7px;
    height: 7px;
    background: var(--secondary-color);
    top: 8px;
    left: 0;
    border-radius: 50%;
  }
  
  @media only screen and (max-width: 767px) {
    .about__content--author.mb-50 {
      margin-bottom: 2rem;
    }
  }
  
  .about__content--author__text {
    padding-left: 1.2rem;
  }
  
  @media only screen and (min-width: 480px) {
    .about__content--author__text {
      padding-left: 2rem;
    }
  }
  
  @media only screen and (min-width: 992px) {
    .about__content--author__text {
      padding-left: 2.8rem;
    }
  }
  
  .about__content--author__name {
    font-size: 1.6rem;
    line-height: 2.4rem;
    margin-bottom: 0.3rem;
  }
  
  @media only screen and (min-width: 992px) {
    .about__content--author__name {
      font-size: 1.7rem;
      line-height: 2.6rem;
    }
  }
  
  .about__content--author__rank {
    color: var(--text-gray-color);
    font-weight: 500;
  }
  
  .about__author--signature {
    margin-left: 1.5rem;
  }
  
  @media only screen and (min-width: 992px) {
    .about__author--signature {
      margin-left: 2.5rem;
    }
  }
  
  @media only screen and (max-width: 991px) {
    .about__author--signature {
      max-width: 85px;
    }
  }
  
  @media only screen and (max-width: 479px) {
    .about__author--signature {
      max-width: 82px;
      margin-left: 1.2rem;
    }
  }
  
  .about__section--bg {
    background: url(../../img/banner/banner-bg2.webp);
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: cover;
  }
  
  .image__with--text__section--bg {
    background: url(../../img/banner/banner-bg6.webp);
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: cover;
    position: relative;
  }
  
  .image__with--text__section--bg::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: #2A2A2A;
    top: 0;
    left: 0;
    opacity: 0.81;
  }
  
  .image__with--text__thumbnail--style2 {
    position: relative;
    z-index: 9;
  }
  
  .image__with--text__thumbnail--img__style2 {
    margin: 0 auto;
  }
  
  .image__with--text__content--style2 {
    position: relative;
    z-index: 9;
  }
  
  @media only screen and (max-width: 767px) {
    .image__with--text__content--style2 {
      text-align: center;
      margin-top: 3rem;
    }
  }
  
  @media only screen and (max-width: 767px) {
    .image__with--text__content--style2.style3 {
      text-align: left;
      margin-top: 0;
    }
  }
  
  @media only screen and (max-width: 575px) {
    .image__with--text__content--style2.style3 {
      text-align: center;
    }
  }
  
  @media only screen and (min-width: 1600px) {
    .image__with--text__title--style2 {
      font-size: 4rem;
      line-height: 5rem;
    }
  }
  
  @media only screen and (min-width: 1200px) {
    .image__with--text__desc--style2 {
      width: 86%;
      line-height: 2.8rem;
    }
  }
  
  .image__with--text__content--price .old__price {
    font-size: 1.6rem;
    color: var(--white-color);
    margin-left: 0;
  }
  
  @media only screen and (min-width: 768px) {
    .image__with--text__content--price .old__price {
      font-size: 1.8rem;
    }
  }
  
  @media only screen and (min-width: 1200px) {
    .image__with--text__content--price .old__price {
      font-size: 2rem;
    }
  }
  
  .image__with--text__content--price .current__price {
    font-size: 1.8rem;
    color: var(--white-color);
  }
  
  @media only screen and (min-width: 768px) {
    .image__with--text__content--price .current__price {
      font-size: 2rem;
    }
  }
  
  @media only screen and (min-width: 1200px) {
    .image__with--text__content--price .current__price {
      font-size: 2.2rem;
    }
  }
  
  .image__with--text__content--price .price__divided {
    height: 0.3rem;
    margin: 0 0.5rem;
    background: var(--white-color);
  }
  
  .image__with--text__section--bg2 {
    background: url(../../img/banner/banner-bg9.webp);
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: 0%;
    background-size: cover;
    position: relative;
    height: 300px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  
  @media only screen and (min-width: 768px) {
    .image__with--text__section--bg2 {
      height: 350px;
      background-position: 10%;
    }
  }
  
  @media only screen and (min-width: 992px) {
    .image__with--text__section--bg2 {
      height: 410px;
      background-position: 0%;
    }
  }
  
  @media only screen and (min-width: 1200px) {
    .image__with--text__section--bg2 {
      height: 510px;
      background-position: center center;
    }
  }
  
  @media only screen and (min-width: 1600px) {
    .image__with--text__section--bg2 {
      height: 630px;
    }
  }
  
  @media only screen and (max-width: 575px) {
    .image__with--text__section--bg2 {
      background-size: auto;
    }
  }
  