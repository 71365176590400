/*
   4. Banner CSS
*/

.banner__section--bg{
  background: url(../../img/banner/banner-bg1.webp);
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
  position: relative;
  &::before{
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: #2A2A2A;
    opacity: 0.6;
    left: 0;
    bottom: 0;
  }
}

.banner__video--play{
  &.mb-35{
    @media only screen and (max-width: 479px) {
      margin-bottom: 2rem;
    }
  }
  &__icon{
    margin: 0 auto;
    background: inherit;
    color: var(--white-color);
    border: 3px solid var(--white-color);
    width: 5.5rem;
    height: 5.5rem;
    @media only screen and (min-width: 992px) {
      width: 6rem;
      height: 6rem;
    }
    &:hover{
      border-color: var(--secondary-color);
    }
  }
}
.banner__video{
  &--content{
    z-index: 9;
  }
  &--title{
    @media only screen and (max-width: 479px) {
      font-size: 1.9rem;
      line-height: 2.7rem;
    }
  }
  &--info{
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 1.8rem;
    @media only screen and (min-width: 576px) {
      font-size: 1.6rem;
      margin-bottom: 2rem;
    }
    @media only screen and (min-width: 992px) {
      font-size: 1.8rem;
      margin-bottom: 2.8rem;
    }
    @media only screen and (min-width: 1200px) {
      font-size: 2.2rem;
      margin-bottom: 3.8rem;
    }
  }
}

.drone__position--img{
  position: absolute;
  bottom: -112px;
  right: 30px;
  @media only screen and (min-width: 1200px) and (max-width: 1599px){
    max-width: 410px;
    bottom: -66px;
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px){
    max-width: 320px;
    bottom: -66px;
  }
  @media only screen and (max-width: 991px) {
    position: inherit;
    bottom: inherit;
    right: inherit;
    max-width: 350px;
    margin: 0 auto 3rem;
  }
  @media only screen and (max-width: 575px) {
    max-width: 100%;
    padding: 0 3rem;
    margin: 0 auto 2.5rem;
  }
  @media only screen and (max-width: 479px) {
    padding: 0 2rem;
  }
}


// home three css here
.display__banner--bg{
  background: url(../../img/banner/banner-bg7.webp);
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
  padding: 6rem 0;
  position: relative;
  @media only screen and (min-width: 768px) {
    padding: 7rem 0;
  }
  @media only screen and (min-width: 992px) {
    padding: 8rem 0;
  }
  @media only screen and (min-width: 1200px) {
    padding: 9rem 0;
  }
  &::before{
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: #F2F2F2;
    left: 0;
    top: 0;
    opacity: 0.59;
  }
  
}

.display__banner--content{
  background: rgba(255, 255, 255, 0.65);
  width: 90%;
  padding: 3rem 2rem;
  margin: 0 auto;
  position: relative;
  z-index: 9;
  border-radius: 10px;
  @media only screen and (min-width: 768px) {
    width: 80%;
    padding: 4rem 3rem;
  }
  @media only screen and (min-width: 1200px) {
    width: 68%;
    padding: 7rem 3rem;
  }
  &__title{
    margin-bottom: 1.2rem;
    @media only screen and (min-width: 992px) {
      margin-bottom: 1.5rem;
    }
  }
  &__desc{
    width: 100%;
    margin: 0 auto 1.8rem;
    @media only screen and (min-width: 576px) {
      width: 90%;
      margin: 0 auto 2rem;
    }
    @media only screen and (min-width: 992px) {
      width: 80%;
      margin: 0 auto 2.8rem;
    }
    @media only screen and (min-width: 1600px) {
      width: 60%;
    }
  }
}