/*
   16. Accordion Css
*/

.accordion__section{
    &--inner{
        & .row{
            @media only screen and (max-width: 767px) {
                flex-direction: column-reverse;
            }
        }
    }
}

.accordion__banner{
    @media only screen and (max-width: 767px) {
        text-align: center;
    }
}
.accordion__wrapper{
    @media only screen and (max-width: 767px) {
        margin-bottom: 30px;
    }
}
.accordion__items{
    &.active{
        margin-bottom: 20px;
        &:last-child{
            margin-bottom: 0;
        }
        & .accordion__items--button__icon--svg{
            transform: rotate(180deg);
        }
    }
    &--body{
        display: none;
        padding: 0 20px;
        &__desc{
            font-size: 1.5rem;
            line-height: 2.8rem;
        }
    }
    &--button{
        border: 0;
        background: var(--white-color);
        width: 100%;
        padding: 13px 60px 13px 25px;
        text-align: left;
        margin-bottom: 15px;
        font-size: 1.5rem;
        font-weight: 600;
        position: relative;
        @media only screen and (min-width: 992px) {
            padding: 13px 70px 13px 35px;
        }
        @media only screen and (min-width: 1200px) {
            padding: 24px 80px 24px 40px;
        }
        &__icon{
            width: 3rem;
            height: 3rem;
            background: var(--secondary-color);
            color: var(--white-color);
            border-radius: 50%;
            position: absolute;
            top: 50%;
            right: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            transform: translatey(-50%);
            @media only screen and (min-width: 768px) {
                right: 15px;
            }
            @media only screen and (min-width: 992px) {
                right: 35px;
            }
            &--svg{
                transition: var(--transition);
            }
        }
        
        & > *{
            pointer-events: none;
        }
    }
    
}
