/* 
    24. Instagram css 
*/
.instagram__thumbnail {
    overflow: hidden;
    &:hover{
        & .instagram__social--icon{
            transform: translatey(-50%) translatex(-50%) scale(1);
        }
        & .instagram__thumbnail--img{
            transform: scale(1.05);
        }
        &::before{
            opacity: 0.7;
        }
    }
    &::before{
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        background: var(--secondary-color);
        top: 0;
        left: 0;
        opacity: 0;
        transition: var(--transition);
        z-index: 9;
        pointer-events: none;
    }
}
.instagram__social--icon{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 3.5rem;
    height: 3.5rem;
    background: var(--white-color);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: var(--secondary-color);
    transform: translatey(-50%) translatex(-50%) scale(0);
    z-index: 9;
    &:hover{
        background: var(--secondary-color);
        color: var(--white-color);
    }
    @media only screen and (min-width: 992px) {
        width: 4rem;
        height: 4rem;
    }
    @media only screen and (min-width: 1200px) {
        width: 5rem;
        height: 5rem;
    }
    & svg{
        @media only screen and (max-width: 1199px) {
            width: 1.8rem;
        }
    }
}